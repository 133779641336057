import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, TextField, Button, Box, Grid } from '@material-ui/core';

import currentReceiptList from 'utils/fetchProductsInCurrentReceipt';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#35DEBB',
      color: '#000',
    },
  },
  close:{
    position:'absolute',
    right: '10px',
    cursor: 'pointer'
  },
  title: {
    flexGrow: 1,
  },
}));

const RFModal = (props) => {
  const classes = useStyles();
  const { backendURL, username } = props;
  const openModal = props.open;
  const closeModal = props.close;
  const { updateShoppingCart } = props;

  const [currentList, setCurrentList] = useState();
  const [barcode, setBarcode] = useState(''); //Barcode Textfield
  const [deleteItem, setDeleteItem] = useState('false'); //Delete Item Trigger

  //Fetch Product list in current receipt
  useEffect(() => {
    if (openModal) {
      setBarcode('');
      const listCurrentReceipt = async () => {
        setCurrentList(await currentReceiptList(backendURL, username));
      };
      listCurrentReceipt()
    }
  }, [openModal, backendURL]);

  //Delete selected item from list
  useEffect(() => {
    const deleteSelectedItem = async () => {
      if (deleteItem === true) { //Enter key o Button pressed
        if (barcode.length === 12) { //Barcode complete - 12 numbers
          await Axios.delete(`${backendURL}/shoppingCart/${username}/${barcode}`);
          setDeleteItem(false); //Item deleted
          closeModal(); //close modal
          updateShoppingCart();
        }
      }
    };
    deleteSelectedItem();
  }, [deleteItem, backendURL, barcode, closeModal, currentList, updateShoppingCart])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setDeleteItem(true);
    }
  };

  return (
    <Dialog open={openModal} onClose={closeModal} className={classes.root} fullWidth maxWidth ='xs'>
      <Toolbar title={'Eliminar Producto'} close={closeModal}/>
      <Box component='div' m={2}>
        <Grid item xs={12} >
          <TextField
            label="Código de barras"
            type="search"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={barcode}
            onChange={(e) => { setBarcode(e.target.value) }}
            onKeyPress={handleKeyPress}
            fullWidth
            autoFocus
            required
            inputProps={{
              maxLength: 12
            }}
          />
        </Grid>
          </Box>
          <Box component='div' mb={2} ml={2} mr={2}>
            
        <Grid item xs={12}>
          <Button
            fullWidth
            type='button'
            variant='contained'
            onClick={() => { setDeleteItem(true) }}
            {...((barcode.length < 12) ? { disabled: true } : { disabled: false })}
            >
            Eliminar Producto </Button>
        </Grid>
            </Box>
    </Dialog>
  );
};

export default RFModal;