/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';

import handleErrorFunction from 'utils/deleteLastProductInReceipt';
import RFModal from './RFModal';
import DiscountModal from './DiscountModal';
import SearchModal from './SearchModal';

import OperatorContext from "components/contexts/OperatorContext";

function SpecialFunctionButton(props) {
  const operatorInfoProvided = useContext(OperatorContext);//Context Consumed
  const [backendURL, setBackendURL] = useState('');//Context Consumed
  const specialFunction = props.nameFunction;
  const { updateShoppingCart } = props; //Error Button
  const [openRFModal, setOpenRFModal] = useState(false); //RF Button
  const [openDiscountModal, setOpenDiscountModal] = useState(false); //Discount Button
  const [openSearchModal, setOpenSearchModal] = useState(false);//Search Button

  const buttonSize = '11.07vh';
  //---------------------------------- Context ----------------------------------------
  useEffect(() => {
    if(operatorInfoProvided.backendURL){
      setBackendURL(operatorInfoProvided.backendURL);
    }
  }, [operatorInfoProvided.backendURL])
  //---------------------------------- Functions --------------------------------------

  const rfFunction = () => {
    console.log('Refund Button Pressed');
    setOpenRFModal(true);
  }

  const errorFunction = () => {
    console.log('Error Button Pressed');
    updateShoppingCart();
    handleErrorFunction(backendURL, operatorInfoProvided.username);
  }

  const discountFunction = () => {
    console.log('Discount Button Pressed');
    setOpenDiscountModal(true);
  };

  const searchFunction = () => {
    console.log('Search Product Button Pressed');
    setOpenSearchModal(true);
  };

  const closeModal = useCallback(() => {
    setOpenRFModal(false);
    setOpenDiscountModal(false);
    setOpenSearchModal(false);
  },[]);

  const updateSC = useCallback(() => {
    updateShoppingCart();
  },
    [updateShoppingCart]
  );

  const handleSpecialFunction = (e) => {
    switch (specialFunction) {
      case 'RF':
        rfFunction();
        break;
      case 'Error':
        errorFunction();
        break;
      case 'Buscar':
        searchFunction();
        break;
      case '%':
        discountFunction();
        break;
      default:
        console.log('Error HandleSpecialFunction');
    }
  }

  var buttonProps = {
    style: {}
  };

  switch (specialFunction) {
    case 'RF':
      buttonProps.style = { backgroundColor: "#D32F2F", height: buttonSize, width: buttonSize, marginRight: "0.75rem", fontSize: '1rem', color: '#FFFFFF' }
      break;
    case 'Error':
      buttonProps.style = { backgroundColor: "#D32F2F", height: buttonSize, width: buttonSize, marginRight: "0.75rem", fontSize: '1rem', color: '#FFFFFF' }
      break;
    case '%':
      buttonProps.style = { backgroundColor: "#29B6F6", height: buttonSize, width: buttonSize, marginRight: "0.75rem", fontWeight: 'bold', fontSize: '1.5rem' }
      break;
    case 'Buscar':
      buttonProps.style = { backgroundColor: "#FFB300", height: buttonSize, width: buttonSize }
      break;
    default:
    //nothing
  }

  return (
    <div style={{ display: "inline" }}>
      {(specialFunction !== 'Buscar') ?
        (<Button
          type="button"
          variant='contained'
          onClick={handleSpecialFunction}
          {...buttonProps}>
          {specialFunction}
        </Button>) : (<Button
          type="button"
          variant='contained'
          onClick={handleSpecialFunction}
          {...buttonProps}>
          <SearchIcon fontSize='large'></SearchIcon>
        </Button>)
      }
      {/* RF Button Modal */}
      <RFModal open={openRFModal} close={closeModal} backendURL={backendURL} username={operatorInfoProvided.username} updateShoppingCart={updateSC}></RFModal>
      <DiscountModal open={openDiscountModal} close={closeModal} backendURL={backendURL}></DiscountModal>
      <SearchModal open={openSearchModal} close={closeModal} backendURL={backendURL}></SearchModal>
    </div>
  );
}

export default SpecialFunctionButton;