import React, { useCallback, useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
  Paper,
} from '@material-ui/core'
import rowsData from './data';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import ItemModalWindow from './ModalWindow';
import AddModalWindow from './AddModalWindow';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/HighlightOff';
import { stableSort, getSorting } from 'utils/tableSorting'

export default function CustomersList(props) {
  const { classes } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRowsData] = useState([]);
  const [employeesQty, setEmployeesQty] = useState(0);
  const [openItemModal, setItemModal] = useState(false)
  const [userId, setUserId] = useState('')
  const [openAddModal, setAddModal] = useState(false)

  //--------------------- Load Rows Data ---------------------
  useEffect(() => {
    const dataFetch = async () => {
      // console.log(await rowsData());
      setRowsData(await rowsData());
    };
    dataFetch()
  }, [])

  useEffect(() => {
    if (rows) {
      setEmployeesQty(rows.length)
      // console.log(rows.length);
    }
  }, [rows])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  //----------------- Modal Control ---------------
  const handleCloseModal = useCallback(async () => {
    setRowsData(await rowsData())
    setItemModal(false);
    setAddModal(false);
    // console.log('Cerrando Modal');
  },[])

  const handleClick = (event, id) => {
    setUserId(id)
    setItemModal(true);
    // console.log(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectedFieldChange = useCallback(async (data) => {
    // setSearchFields(data);
    setRowsData(await rowsData(data))
    // console.log(data);
  }, [])

  const handleOpenAddModal = useCallback(() => {
    setAddModal(true);
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          fieldsChange={handleSelectedFieldChange}
          openAddModal={handleOpenAddModal}
          employees={employeesQty}
          classes={props.enhTableToolbarClasses}
        />
        <TableContainer>
          <Table
            className={classes.table}
            size='small'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.length >= 1 ? (
                stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onTouchStart={event => handleClick(event, row._id)}
                        onDoubleClick={event => handleClick(event, row._id)}
                        price="checkbox"
                        tabIndex={-1}
                        key={row._id}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.status}</TableCell>
                        <TableCell align="center">{row.username}</TableCell>
                        <TableCell align="center">{row.address}</TableCell>
                        <TableCell align="center"><a href={`https://wa.me/57${row.whatsapp}`} target="_blank" rel="noopener noreferrer">{row.whatsapp}</a></TableCell>
                        <TableCell align="center"><a href={`tel:${row.cellphone}`}>{row.cellphone}</a></TableCell>
                        <TableCell align="center">{row.role}</TableCell>
                        <TableCell align="center" className="iconCell" onClick={event => handleClick(event, row._id)}><EditIcon /></TableCell>
                        <TableCell align="center" className="iconCell"><BlockIcon /></TableCell>
                      </TableRow>
                    );
                  })) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ItemModalWindow open={openItemModal} close={handleCloseModal} id={userId} classes={props.itemModalClasses} />
      <AddModalWindow open={openAddModal} close={handleCloseModal} classes={props.itemModalClasses} />
    </div>
  );
}