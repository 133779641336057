import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box, TextField, InputAdornment } from '@material-ui/core';
import CashIcon from 'icons/Cash';
import DebitCard from 'icons/DebitCard';
import CreditCard from 'icons/CreditCard';
import Customer from '@material-ui/icons/Face';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function TextFieldModel(props) {
  const { amount, value, method, paymentUpdate, ...other } = props;
  const [payments, setPayments] = useState('')

  //CHECK IF PREVIOUS VALUES ARE SET
  useEffect(() => {
    const initialCheck = () => {
      if (amount.method !== 0) {
        setPayments(amount[method]);
      }
    };
    initialCheck();
  }, [])

  //HANDLE PAYMENT AMOUNT CHANGE - LOCALLY
  const handlePaymentChange = (event, method) => {
    setPayments(event.target.value)
  }

  //CHECK AND UPDATE PAYMENT AMOUNT AND METHOD
  useEffect(() => {
    paymentUpdate(method, payments);
  }, [method, payments])

  return (
    <TextField
      value={payments || ''}
      onChange={(e) => handlePaymentChange(e, method)}
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
      variant="standard"
      autoFocus
      {...other}
    />
  );
}

TextFieldModel.propTypes = {
  method: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    '& .MuiTabs-flexContainer .MuiTab-root:first-child .MuiTab-wrapper': {
      flexDirection: 'row',
      '& .MuiSvgIcon-root': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    '& .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    '& .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root': {
      minWidth: '0px'
    },
    '& .MuiTabs-scroller .MuiButtonBase-root .MuiTab-wrapper svg': {
      marginLeft: '0px',
      marginRight: '4px',
      textAlign: 'left',
    },
  },
  main: {
    height: "calc(100% - 54px)",
    display: 'flex',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiBox-root': {
        padding: "0px",
      },
    }
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
    '& .MuiInputBase-root .MuiInputAdornment-root .MuiTypography-body1 ': {
      fontSize: '1.5rem',
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      textAlign: 'right',
      fontSize: '2.5rem',
    }
  },
}));

export default function FullWidthTabs(props) {
  const { paymentInfoUpdate} = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [amount, setAmount] = useState({
    "cash": 0,
    "credit-card": 0,
    "debit-card": 0,
    "credit-customer": 0
  })

  //HANDLE TAB CHANGE
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //HANDLE PAYMENT CHANGE(AMOUNT OR METHOD)
  const handlePaymentChange = (methodData, amountData) => {
    setAmount({ ...amount, [methodData]: parseInt(amountData) || 0 })
  }

  // EXPORT PAYMENT INFO TO PARENT COMPONENT
  useEffect(()=>{
    const exportPaymentInfo = () => {
      paymentInfoUpdate(amount);
    };
    exportPaymentInfo();
  },[amount])

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Cash" icon={<CashIcon />} />
        <Tab label="CR" icon={<CreditCard />} />
        <Tab label="DB" icon={<DebitCard />} />
        <Tab label="CL" icon={<Customer />} />
      </Tabs>
      <main className={classes.main}>
        <TabPanel value={value} index={0}>
          <TextFieldModel
            method='cash'
            className={clsx(classes.margin, classes.textField)}
            paymentUpdate={handlePaymentChange}
            amount={amount}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <TextFieldModel
            method='credit-card'
            className={clsx(classes.margin, classes.textField)}
            paymentUpdate={handlePaymentChange}
            amount={amount}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <TextFieldModel
            method='debit-card'
            className={clsx(classes.margin, classes.textField)}
            paymentUpdate={handlePaymentChange}
            amount={amount}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <TextFieldModel
            method='credit-customer'
            className={clsx(classes.margin, classes.textField)}
            paymentUpdate={handlePaymentChange}
            amount={amount}
          />
        </TabPanel>
      </main>
    </div>
  );
}