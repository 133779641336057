import React, { useState, useEffect, useCallback } from 'react';
// import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button'

import productList from 'utils/fetchProductsInChosenReceipt'
import manageProduct from 'utils/manageProductInReceipt';
import updateReceiptStatus from 'utils/updateReceiptStatus'

const useStyles = makeStyles((theme) => ({
  modal: {
    margin: '10vh auto',
    width: '50vw',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  h1: {
    color: "#000000",
    textAlign: "center",
  },
  div: {
    // display: 'flex',
    minHeight: "95%",
  },
  contentLeft: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    // paddingBottom: "1rem",
  },
  allWindow: {
    width: "100%",
    flexGrow: 1,
    flexBasis: "auto",
    flexShrink: 1,
    justifyContent: "center",
  }
}));

const ReturnModal = (props) => {
  const classes = useStyles();
  const { backendURL } = props;
  const openModal = props.open;
  const closeModal = props.close;
  const { currentTicket } = props;

  const [barcode, setBarcode] = useState('');
  const [returnItem, setReturnItem] = useState(false);
  
  const [currentList, setCurrentList] = useState([]);

  //List every item in the receipt selected 
  useEffect(() => {
    const fetchProducts = async () => {
      if (openModal) {
        var list = await productList(backendURL, currentTicket)
        list = list.filter(item => item.status !== 'returned')
        setCurrentList(list);
        console.log(list);
      }
    };
    fetchProducts();
  }, [openModal, backendURL, currentTicket]);

  const returnItemToStore = useCallback(async () => {
    var result = await manageProduct(backendURL, barcode, 'return', currentList);
    if (result === "Item returned succesfully") {
      var list = await productList(backendURL, currentTicket)
      list = list.filter(item => item.status !== 'returned')
      setCurrentList(list);
      console.log(list);
      updateReceiptStatus(backendURL, currentTicket);
      setBarcode('')
    }else{
      console.log('Item not found, could be returned before.');
    }
  },[backendURL, barcode, currentList, currentTicket])

  //Return Item to Store
  useEffect(() => {
    if (returnItem) {
      returnItemToStore();
      //SHOW MESSAGE
      setReturnItem(false);
    }
  }, [returnItem, returnItemToStore])

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setReturnItem(true);
    }
  }

  return (
    <Modal open={openModal} onClose={closeModal}>
      <div className={classes.modal}>
        <h1 className={classes.h1}>Devolver Producto</h1>
        <div className={classes.div}>
          <div className={classes.contentLeft}> {/** Search Fields */}
            <TextField
              // id="outlined-search"
              label="Código de barras"
              type="search"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={barcode}
              onChange={(e) => { setBarcode(e.target.value) }}
              onKeyPress={handleKeyPress}
              fullWidth
              autoFocus
              required
              inputProps={{
                maxLength: 12
              }}
              style={{ marginTop: 0 }}
            />
            <Button
              type='button'
              variant='contained'
              onClick={() => { setReturnItem(true) }}
              {...((barcode === '') ? { disabled: true } : { disabled: false })}
            >
              Devolver Producto
          </Button>
            {/* <Button
              type='button'
              variant='contained'
              onClick={() => { setReturnTicket(true) }}
            >
              Devolver Todo
          </Button> */}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReturnModal;