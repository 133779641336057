import React, {memo} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Button, Table, TableHead, TableBody, TableRow,TableCell} from '@material-ui/core'
import Title from '../../Title';
import rows from './data'

const useStyles = makeStyles(theme => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

 const Orders = memo ((props) =>{
  const classes = useStyles();
  const {setSalesTab} = props;
  const preventDefault = (event) => {
    event.preventDefault();
    setSalesTab();
  }
  return (
    <React.Fragment>
      <Title>Pedidos Pendientes</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Fecha</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>Enviar a</TableCell>
            <TableCell>Forma de Pago</TableCell>
            <TableCell align="right">Total Venta</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell>{row.date}</TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.shipTo}</TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{row.amount}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className={classes.seeMore}>
        <Button color="primary" href="#" onClick={preventDefault}>
          Ver más pedidos
        </Button>
      </div>
    </React.Fragment>
  );
})

export default Orders