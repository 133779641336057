import React, { useState, useEffect, useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import {
  MenuItem, TextField, InputAdornment, Button,
  Table, TableBody, TableCell, TableHead, TableRow,
  Typography, Box, Dialog, Paper, Grid, Divider
} from '@material-ui/core'

import 'CSS/Ventas.css';
import axios from 'axios';
import clsx from 'clsx';
import _ from 'lodash';

//import openSocket from 'socket.io-client';
import SpecialButtonsContainer from './Ventas/SpecialFunctionButtonsContainer';
import Toolbar from './Ventas/Toolbar'
import CheckOutWindow from './Ventas/CheckOutWindow';

//import completePluCode from 'utils/completePluCode'
import searchPluCode from 'utils/fetchPluCode';
import currentReceiptProducts from 'utils/fetchProductsInCurrentReceipt';
import getSubtotal from 'utils/getSubtotal';

import SearchButton from './Ventas/SpecialFunctionButton';
// import productList from 'utils/fetchProductsInChosenReceipt';
import updateStock from 'utils/updateProductInventory';

import formatNames from 'utils/setPaymentMethodsNames'
import updateCashier from 'utils/updateCashier';

// ------------------ Context Consumption --------------------------
import OperatorContext from 'components/contexts/OperatorContext';
import Axios from 'axios';

const brandName = 'Carnes de la Costa'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  billingTextfield: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "84vh",
    width: "100%",
    "& .MuiGrid-item": {
      minHeight: "11vh",
      // maxHeight: "11vh",
      // marginTop:theme.spacing(3)
    },
  },
  paperAdmin: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "77vh",
    width: "100%",
    "& .MuiGrid-item": {
      paddingTop: 0,
      paddingBottom: 0,
      maxHeight: "10vh"
    },
  },
  paperLeft: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      height: "100%",
      "& .MuiGrid-item": {
        minHeight: "60px",
        maxHeight: "80px",
        // marginTop:theme.spacing(3)
      },
    },
  },
  paperRight: {
    padding: theme.spacing(0),
  },
  modal: {
    // margin: '10vh auto',
    // width: '50vw',
    // height: '80vh',
    // backgroundColor: theme.palette.background.paper,
    // border: '2px solid #000',
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 3),
  },
  container: {
    display: 'flex',
    minHeight: "100%",
    flexWrap: 'wrap',
    alignItems: "stretch",
  },
  tableWrapper: {
    height: "35vh",
    overflow: 'auto',
    // padding: theme.spacing(1),
  },
  subTotal: {
    fontWeight: "normal",
    textAlign: "left"
  },
  toReceipt: {
    backgroundColor: "#8C0D7B",
    color: "#FFFFFF",
    width: "75%"
  },
  searchButtonContainer: {
    margin: "auto",
    minHeight: "95%",
    display: "flex"
  },
  searchButton: {
    margin: "auto"
  },
  specialButtonsContainer: {
  },
}));

const Ventas = (props) => {
  const operatorInfoProvided = useContext(OperatorContext);//Context Consumed
  const classes = useStyles();
  const { adminView } = props;
  const backendURL = `${process.env.REACT_APP_BACKEND_URL}`

  //Textfield inputs handlers
  const initValues = {
    name: '',
    price: '',
    isWeighted: false,
    weight: '',
    barcode: '',
    barcode2submit: '',
    pluCode: '',
    department: '',
    qty: 1,
    manualPrice: '',
  };
  const [values, setValues] = useState(initValues);
  const [add2ShoppingCart, setAdd2ShoppingCart] = useState(false);
  const [updatedShoppingCart, setUpdatedShoppingCart] = useState(true);

  const [shoppingCart, setShoppingCart] = useState([])
  const [subTotal, setSubTotal] = useState(0)

  //Modal Controller
  const [open, setOpen] = useState(true);

  //Payment, amount, change states
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [amount, setAmount] = useState();

  //Start new Receipt
  const [newReceipt, setNewReceipt] = useState(false);

  //Add new Items
  const [newItem, setNewItem] = useState(false);
  const [addItem, setAddItem] = useState(false);

  //Payment Methods Render
  const [paymentMethods, fetchtMethods] = useState([]);

  //Departments Render 
  const [ranges, setRanges] = useState([]);

  useEffect(() => {
    const effectFunction = async () => {
      var rangesM = await axios.get(`${backendURL}/features/methods`);
      if (rangesM.data[0] !== undefined) {
        rangesM = rangesM.data[0].departments
        var renderedMethods = []
        rangesM.map(department => {
          return renderedMethods.push({ label: department, value: department })
        })
        // console.log(payMethods);
        // console.log(renderedMethods);
        setRanges(renderedMethods)
      }
    };
    effectFunction();
    // console.log(operatorInfoProvided);//Testing Context Consumed
  }, [backendURL, operatorInfoProvided]);

  //Payment Methods
  useEffect(() => {
    const effectFunction = async () => {
      var payMethods = await axios.get(`${backendURL}/features/methods`);
      if (payMethods.data[0] !== undefined) {
        payMethods = payMethods.data[0].paymentMethods
        var renderedMethods = []
        payMethods.map(method => {
          return renderedMethods.push({ value: method, label: formatNames(method) })
        })
        // console.log(payMethods);
        // console.log(renderedMethods);
        fetchtMethods(renderedMethods)
      }
    };
    effectFunction();
  }, [backendURL]);

  // //Find Product in database with PLU code
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const productInfo = await searchPluCode(backendURL, pluCode);

  //     if (productInfo !== undefined) {
  //       setName(productInfo.name);
  //       setPrice(productInfo.price);
  //       setDpt(productInfo.department);
  //       setIsWeighted(productInfo.weighted);
  //       setBarcode2Submit(productInfo.barcode);
  //     }
  //     else {
  //       setName('')
  //       setPrice('')
  //       setDpt('')
  //       setIsWeighted(false);
  //       setBarcode2Submit('');
  //       // setWeight('')
  //     }
  //   };
  //   fetchData();
  // }, [pluCode, backendURL]);

  // const updateShoppingCart = useCallback(() => {
  //   setNewReceipt(true);
  // setUpdatedShoppingCart(true);
  // }, [])

  // //Submit barcode Item automatically
  // useEffect(() => {
  //   const barcodeComplete = async (event) => {
  //     if (barcode.length === 12) {
  //       // console.log(barcode.length);
  //       var result = '';
  //       //If a product has 9 in second position is a weighted product
  //       if (barcode[1] === '9') {
  //         const Kgs = barcode.substring(7, 9); //We get kg numbers
  //         const gs = barcode.substring(9, 12); //We get grames numbers or decimals
  //         const weightFormatted = `${Kgs}.${gs}`; //Put everything together
  //         setWeight(parseFloat(weightFormatted)); //Set weight for the item
  //         const plu = barcode.substring(3, 7); //We get plu code to fetch database
  //         result = await axios.get(`${backendURL}/products/search?plu=${plu}`);
  //       } else {
  //         result = await axios.get(`${backendURL}/products/search?barcode=${barcode}`);
  //       }
  //       console.log(result.data[0]);
  //       if (result.data[0]) {
  //         setName(result.data[0].name);
  //         setPrice(result.data[0].price);
  //         setDpt(result.data[0].department);
  //         setPluCode(result.data[0].PLU)
  //       }
  //       if (addItem === true) {
  //         // Se cargan los id maximos para las futuras peticiones
  //         const maxIDs = await axios.get(`${backendURL}/counters`); //Se cargan los indices máximos para hacer las peticiones
  //         // Se cargan productos en recibo actual
  //         updateShoppingCart();

  //         //Verificacion de producto, posteo de nueva operacion (agregar producto al carrito), actualizacion de maxIDs, actualizacion de resumen y reinicio de campos de busqueda
  //         if (result.data[0] !== undefined) { //Si tiene nombre, tiene precio asociado
  //           var weighted = qty;
  //           if (barcode[1] === 9) {
  //             weighted = weight;
  //           }
  //           await axios.post(`${backendURL}/operations`, {
  //             name: result.data[0].name,
  //             receipt: maxIDs.data[0].receipt,
  //             clientName: "Test",
  //             PLU: result.data[0].PLU,
  //             department: result.data[0].department,
  //             qty: weighted,
  //             weight: weight,
  //             price: result.data[0].price,
  //             barcode: result.data[0].barcode
  //           });

  //           updateShoppingCart();
  //           setNewItem(true);
  //         }
  //       }
  //     }
  //     else {
  //       setName('');
  //       setPrice('');
  //       setDpt('');

  //     }
  //     setAddItem(false)
  //   };
  //   barcodeComplete();
  // }, [addItem, backendURL, barcode, qty, weight, updateShoppingCart]);

  //Used after submit to reset textfield inputs
  // useEffect(() => {
  //   setBarcode('');
  //   setPluCode('');
  //   // console.log(barcode, pluCode);
  //   setNewItem(false);
  // }, [newItem])

  // Recuperación de recibo actual, en caso de cierre o actualizacion de la ventana (F5)
  // useEffect(() => {
  //   const fetchCurrentReceipt = async () => {
  //     var currentReceipt = await currentReceiptProducts(backendURL);
  //     setShoppingCart(currentReceipt)
  //     setSubTotal(getSubtotal(currentReceipt));
  //     setNewReceipt(false)
  //   };
  //   fetchCurrentReceipt();
  // }, [newReceipt, backendURL])

  //Websockets connections ie. barcode, and others
  // useEffect(()=>{
  //   const socket = openSocket('http://localhost:8000');
  //   socket.on('scale', (data)=>{
  //     setWeight(data.value)
  //   });
  // },[])

  // Formatting number display on Disabled inputFields, and table rows.
  // const formatNumber = (number, typeOfNumber) => {
  //   if (typeOfNumber === 'int') {
  //     if (isNaN(parseInt(number).toLocaleString()) === false) {
  //       return parseInt(number).toLocaleString()
  //     }
  //     else {
  //       return ('')
  //     }
  //   } else if (typeOfNumber === 'float') {
  //     if (isNaN(parseFloat(number).toLocaleString()) === false) {
  //       return parseFloat(number).toLocaleString()
  //     }
  //     else {
  //       return ('')
  //     }
  //   }
  // }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handleSubmit = async (event) => {
  //   // Se cargan los id maximos para las futuras peticiones
  //   const maxIDs = await axios.get(`${backendURL}/counters`); //Se cargan los indices máximos para hacer las peticiones

  //   // Se cargan productos en recibo actual
  //   updateShoppingCart()

  //   var weighted = qty;

  //   if (isWeighted === true) {
  //     weighted = weight;
  //   }
  //   await axios.post(`${backendURL}/operations`, {
  //     name: name,
  //     receipt: maxIDs.data[0].receipt,
  //     clientName: "Test",
  //     PLU: pluCode,
  //     department: dpt,
  //     qty: weighted,
  //     price: price,
  //     barcode: barcode2submit
  //   });

  //   updateShoppingCart();
  //   setNewItem(true);
  // }

  // const handleKeyPress = async event => {
  //   if (event.key === 'Enter') {
  //     setAddItem(true)
  //     console.log('Presionaste Enter');
  //     event.preventDefault();
  //   }
  // }

  const showShoppingCart = () => {
    return (
      <div className={classes.tableWrapper}>
        <Table stickyHeader size='small'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>#</TableCell>
              <TableCell align='center'>Producto</TableCell>
              <TableCell align='center'>Qty</TableCell>
              <TableCell align='center'>Precio</TableCell>
              <TableCell align='center'>SubTotal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {shoppingCart.map((item, i) => (
              <TableRow key={i + 1}>
                <TableCell align='center'>{i + 1}</TableCell>
                <TableCell align='center'>{_.truncate(item.name, { 'length': 10 })}</TableCell>
                <TableCell align='center'>{parseFloat(item.qty).toLocaleString()}</TableCell>
                <TableCell align='center'>{_.round(item.price).toLocaleString('de-DE')}</TableCell>
                <TableCell align='center'>{_.round(item.price * item.qty).toLocaleString('de-DE')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    )
  }

  // const handleCloseReceipt = async () => {
  //   //Amount has to be the same as subTotal or greater
  //   if (amount >= subTotal) {
  //     var prodDate = await axios.get(`${backendURL}/features/methods`);
  //     prodDate = prodDate.data[0].productionDate
  //     await axios.post(`${backendURL}/closedReceipts`, {
  //       receipt: shoppingCart[0].receipt,
  //       // clientName: shoppingCart[0].clientName,
  //       cliente: 'Test',
  //       total: subTotal,
  //       paymentMethod: paymentMethod,
  //       payed: amount,
  //       change: amount - subTotal,
  //       status: "active",
  //       productionDate: prodDate
  //     });
  //     const maxIDs = await axios.get(`${backendURL}/counters`); //Se cargan los indices máximos para hacer las peticiones
  //     await axios.put(`${backendURL}/counters`, {
  //       operations: maxIDs.data[0].operations,
  //       receipt: maxIDs.data[0].receipt + 1,
  //       // users: maxIDs.data.users,
  //       // products: maxIDs.data.products
  //     });

  //     updateCashier(backendURL, subTotal, paymentMethod, 'sell');
  //     updateStock(backendURL, maxIDs.data[0].receipt, 'sell')
  //     //PRINT RECEIPT AND OPEN CASHIER
  //     handleClose();
  //     setNewReceipt(true);
  //     setSubTotal(0);
  //     // setPluCode('');
  //   }
  // }

  // LOOK FOR PRODUCTS WITH PLUCODE
  useEffect(() => {
    const fetchData = async () => {
      if (values.pluCode.length === 4) {
        const productInfo = await searchPluCode(backendURL, values.pluCode);
        // console.log(values)
        if (productInfo !== undefined) {
          setValues({
            ...values,
            name: productInfo.name,
            price: productInfo.price,
            department: productInfo.department,
            isWeighted: productInfo.isWeighted,
            barcode2submit: productInfo.barcode
          })
        }
        console.log(values)
      } else {
        setValues({
          ...values,
          name: initValues.name,
          price: initValues.price,
          department: initValues.department,
          isWeighted: initValues.isWeighted,
          barcode2submit: initValues.barcode
        })
      }
    }
    fetchData();
  }, [values.pluCode])

  // LOOK FOR PRODUCTS IN CURRENT USER SHOPPING CART AND UPDATE SUBTOTAL
  useEffect(() => {
    const fetchShoppingCart = async () => {
      var res = await axios.get(`${operatorInfoProvided.backendURL}/shoppingCart/${operatorInfoProvided.username}`);
      console.log(updatedShoppingCart)
      if (res.data !== null && updatedShoppingCart) {
        const data = res.data.products
        setShoppingCart(data)
        setSubTotal(getSubtotal(data))
      }
      setUpdatedShoppingCart(false);
    };
    fetchShoppingCart()
  }, [shoppingCart, updatedShoppingCart])

  // CHECK IF PRODUCT IS READY TO BE ADDED TO SHOPPING CART
  useEffect(() => {
    // console.log(values)
    if (values.isWeighted === false) {
      if (values.name && values.price && values.department && values.qty > 0) {
        setAdd2ShoppingCart(true);
      } else {
        setAdd2ShoppingCart(false)
      }
    } else if (values.isWeighted === true) {
      if (values.name && values.price && values.department && values.weight) {
        setAdd2ShoppingCart(true);
      } else {
        setAdd2ShoppingCart(false)
      }
    }
  }, [values])

  const handleChange = name => (event) => {
    setValues({
      ...values,
      [name]: event.target.value
    })
  }

  const handleKeyPress = () => {
    console.log('Handle Key Press');
  }

  // HANDLE PRODUCTS SUBMIT
  const handleSubmit = async () => {
    var products = shoppingCart;
    var product2ShoppingCart = {
      name: values.name,
      department: values.department,
      price: _.round(values.isWeighted ? (values.price * values.weight) : (values.price)),
      isWeighted: values.isWeighted,
      PLU: values.pluCode,
      barcode: values.barcode2submit,
      qty: values.isWeighted ? (values.weight) : (values.qty)
    }
    products.push(product2ShoppingCart);
    const res = await axios.post(`${operatorInfoProvided.backendURL}/shoppingCart/${operatorInfoProvided.username}`, { products });
    if (res.data === 'Products added succesfully.') {
      setValues(initValues);
    }
  }

  const updateShoppingCart = useCallback(() => {
    setUpdatedShoppingCart(true);
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid container item xs={12} sm={7} md={8}>
          <Paper className={clsx(adminView ? classes.paperAdmin : classes.paper, classes.paperLeft)}>
            <form
              className={classes.container}
              noValidate
              autoComplete="off"
            >
              <Grid container spacing={2} justify='center'>
                <Grid item xs={7} sm={6}>
                  <TextField
                    disabled
                    fullWidth
                    label="Nombre del Producto"
                    value={values.name} // eslint-disable-next-line
                    margin="normal"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={5} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Precio del Producto"
                    value={_.round(values.price).toLocaleString('de-DE')} // eslint-disable-next-line
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={7} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Peso del Producto"
                    value={values.weight} // eslint-disable-next-line
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={5} sm={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Precio según Peso"
                    value={values.isWeighted ? parseInt(values.price * values.weight) : ''} // eslint-disable-next-line
                    margin="normal"
                    variant="outlined"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Código de barras"
                    type="search"
                    margin="normal"
                    variant="outlined"
                    value={values.barcode}
                    onChange={handleChange('barcode')}
                    onKeyPress={handleKeyPress}
                    autoFocus
                    inputProps={{
                      maxLength: 12
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Código PLU"
                    type="search"
                    margin="normal"
                    variant="outlined"
                    value={values.pluCode}
                    onChange={handleChange('pluCode')}
                    inputProps={{
                      maxLength: 4
                    }}
                  // {...((weight !== '') ? { disabled: true } : { disabled: false })}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    select
                    variant="outlined"
                    label="Departamento"
                    margin="normal"
                    value={values.department}
                    onChange={handleChange('department')}>
                    {ranges.map(option =>
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>)}
                  </TextField>
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Unidades"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={values.qty}
                    onChange={handleChange('qty')}
                    {...((values.weight !== '') ? { disabled: true } : { disabled: false })}
                  />
                </Grid>
                <Grid item xs={6} sm={6}>
                  <TextField
                    fullWidth
                    label="Precio Manual"
                    type="number"
                    margin="normal"
                    variant="outlined"
                    value={values.manualPrice}
                    onChange={handleChange('manualPrice')}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={9} >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={handleSubmit}
                    {... ((add2ShoppingCart) ? { disabled: false } : { disabled: true })}
                  >
                    Agregar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>
        <Grid container item xs={12} sm={5} md={4}>
          <Paper className={clsx(adminView ? classes.paperAdmin : classes.paper, classes.paperRight)}>
            {shoppingCart.length > 0
              ? (
                <div>
                  {showShoppingCart()}
                  <Typography component="div" style={{ padding: "0" }}>
                    <Box component="h2" textAlign="right" m={5}>
                      SubTotal: <span className={classes.subTotal}>${_.round(subTotal).toLocaleString('de-DE')}</span>
                    </Box>
                    <Button type="button" onClick={handleOpen} variant="contained" color="primary" className={classes.toReceipt}>
                      FACTURAR
                    </Button>
                    <Box component="div" className={classes.specialButtonsContainer}>
                      <SpecialButtonsContainer updateShoppingCart={updateShoppingCart} />
                    </Box>
                    <CheckOutWindow 
                      open={open} 
                      close={handleClose} 
                      brandName={brandName}
                      items={shoppingCart}
                      subTotal={subTotal}
                    />
                  </Typography>
                </div>)
              : (
                <div className={classes.searchButtonContainer}>
                  <div className={classes.searchButton}>
                    <SearchButton nameFunction='Buscar' />
                  </div>
                </div>)
            }
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
};

export default Ventas;