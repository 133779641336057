import React, { useState, useEffect, useCallback, useContext } from 'react';
import Axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReturnModal from './Devoluciones/ReturnModal';

import 'CSS/Ventas.css';

import OperatorContext from 'components/contexts/OperatorContext';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const Devoluciones = (props) => {
  const operatorInfoProvided = useContext(OperatorContext);
  const [backendURL, setBackendURL] = useState('');
  const classes = useStyles();
  // const { backendURL } = props; //Backend Address
  const [ticketNumber, setTicketNumber] = useState(''); //Bill Number
  const [searchTicket, setSearchTicket] = useState(false); //Start Ticket Search trigger
  const [openModal, setModal] = useState(false); //Modal to return products. 
  const [currentTicket, setCurrentTicket] = useState(''); //Stores Ticket Number to get passed to Modal
//--------------------------------------------------------------------------------------
useEffect(()=>{
  if(operatorInfoProvided.backendURL){
    setBackendURL(operatorInfoProvided.backendURL);
  }
},[operatorInfoProvided.backendURL])
//--------------------------------------------------------------------------------------
  //Search ticket number and modal open switch to return product!
  useEffect(() => {
    const searchFunction = async () => {
      if (searchTicket && ticketNumber) {
        var listSelectedTicket = await Axios.get(`${backendURL}/operations?receipt=${ticketNumber}`);
        listSelectedTicket = listSelectedTicket.data;
        // console.log(listSelectedTicket);
        if (listSelectedTicket.length > 0) {
          console.log(`Receipt # ${ticketNumber} already exist. Opening Modal Window`);
          setCurrentTicket(ticketNumber); 
          setModal(true);
        }else{
          console.log(`Receipt # ${ticketNumber} doesn´t exist. Check receipt number.`);
        }
        setSearchTicket(false);
      }
    };
    searchFunction()
  }, [searchTicket, ticketNumber, backendURL]);

  const handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      handleClick();
    }
  }
  const handleClick = () => {
    setSearchTicket(true);
  }

  //Controls Modal Close
  const closeModal = useCallback(()=>{
    setModal(false);
    setTicketNumber('');
  },[]);

 return (
    <div className={classes.root} style={{ height: "100%" }}>
      <Grid container spacing={1} style={{ height: "100%" }}>
        <Grid item xs={12} md={12}>
          <Paper className={classes.paper}>
            <div>
              <TextField
                id='outlined-search'
                variant='outlined'
                placeholder='Número de Factura'
                value={ticketNumber}
                onChange={(event) => setTicketNumber(event.target.value)}
                autoFocus
                onKeyPress={handleKeyPress}
              />
              <Button
                type='button'
                variant='contained'
                onClick={handleClick}
              > Buscar Ticket
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <ReturnModal backendURL={backendURL} open={openModal} close={closeModal} currentTicket={currentTicket}/>
    </div>
  )
};

export default Devoluciones;