import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow,
  TableSortLabel, Toolbar, Typography, Paper
} from '@material-ui/core'
import SearchFields from './ReportSelected';
import EditIcon from '@material-ui/icons/Edit';
import BlockIcon from '@material-ui/icons/HighlightOff';

function createData(name, clientStatus, username, clientAddress, whatsapp, cellphone, role) {
  return { name, clientStatus, username, clientAddress, whatsapp, cellphone, role };
}

const rows = [
  createData('Worker1', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker2', 'Activo', "johanaltro", "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker3', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker4', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker5', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker6', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker7', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker8', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker9', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker10', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker11', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
  createData('Worker12', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Admin"),
  createData('Worker13', 'Activo', 'johanaltro', "Calle 45 # 25 - 15",'301-601-2062', "301-601-2062", "Operario"),
];

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Nombre del Empleado' },
  { id: 'clientStatus', numeric: true, disablePadding: false, label: 'Estado' },
  { id: 'username', numeric: true, disablePadding: false, label: 'Usuario' },
  { id: 'clientAddress', numeric: true, disablePadding: false, label: 'Dirección' },
  { id: 'whatsapp', numeric: true, disablePadding: false, label: 'Whatsapp' },
  { id: 'cellphone', numeric: true, disablePadding: false, label: 'Telefono' },
  { id: 'role', numeric: true, disablePadding: false, label: 'Rol' },
  { id: 'edit', numeric: true, disablePadding: false, label: 'Editar' },
  { id: 'unable', numeric: true, disablePadding: false, label: 'Deshabilitar' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  title: {
    flex: '1 1 100%',
    textAlign: "left"
  },
  button: {
    // margin: theme.spacing(1),
    minWidth: "190px"
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {/* {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle">
            Clientes
        </Typography>
        )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )} */}
      <Typography className={classes.title} variant="h5" id="tableTitle" align="left">
        Reportes
      </Typography>
      <SearchFields />
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function CustomersList() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'small'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={event => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.clientStatus}</TableCell>
                      <TableCell align="right">{row.username}</TableCell>
                      <TableCell align="right">{row.clientAddress}</TableCell>
                      <TableCell align="right">{row.whatsapp}</TableCell>
                      <TableCell align="right">{row.cellphone}</TableCell>
                      <TableCell align="right">{row.role}</TableCell>
                      <TableCell align="right" className="iconCell"><EditIcon/></TableCell>
                      <TableCell align="right" className="iconCell"><BlockIcon/></TableCell>
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </div>
  );
}