import React from "react";

// import "CSS/operator.css"
import "./operator.css"

import Auth from "components/RedirectToLoginPage";
import Dashboard from 'components/admin/dashboard/Dashboard2';

const Admin = (props) => {
  return (
    <div>
      {(props.location.state !== undefined) ? (
        <div>
          <Dashboard username={props.location.state.username}/>
        </div>
      ) : (
          <Auth isAuth={false} />
        )}
    </div>
  )
}

export default Admin;
