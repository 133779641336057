import React, { memo } from 'react';
import { Dialog } from '@material-ui/core'
import Toolbar from './Toolbar'
import Appbar from './stock/Navbar'

const ModalWindow = memo((props) => {
  const { open, close } = props

  const handleClose = () => {
    close();
    console.log('Cerrar Discount Modal')
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      disableBackdropClick>
        <Toolbar title="Inventario" close={handleClose}/>
      <Appbar/>
    </Dialog>
  )
})

export default ModalWindow