import React from 'react';
import Grid from '@material-ui/core/Grid';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function MaterialUIPickers() {
  // The first commit of Material-UI
  var d = new Date();
  d.setDate(d.getDate() - 1);
  const [selectedDate, setSelectedDate] = React.useState(d);

  const handleDateChange = date => {
    setSelectedDate(date);
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          maxDate={d.setDate(d.getDate())}
          inputVariant="outlined"
          margin="normal"
          id="date-picker-dialog"
          label="Fecha de Reporte"
          format="DD/MM/YY"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
