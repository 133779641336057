import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import {Paper, Tabs, Tab,Typography, Box} from '@material-ui/core'
// import Paper from '@material-ui/core/Paper';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';

// ---------------------------- Tabs Content Component-----------------------------------
import Ventas from './tabs/Ventas2';
import Devoluciones from './tabs/Devoluciones';
import Reportes from './tabs/ReportesParciales';
import Cierres from './tabs/Cierres';
// ---------------------------- Context Provider ------------------------------------
import { OperatorProvider } from '../contexts/OperatorContext';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#35DEBB",
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems:'stretch'
    // justifyContent: 'space-evenly'

  },
  boxStyleOperator: {
    backgroundColor: "#0097C6",
    padding: theme.spacing(2), 
    paddingBottom: 0,
    minHeight: "88vh",
  },
  boxStyleAdmin: {
    backgroundColor: "#0097C6",
    padding: theme.spacing(2), 
    paddingBottom: 0,
    minHeight: "80.3vh",
  },
  tabStyle: {
    minHeight:"12vh"
  }
}));

function TabPanel(props) {
  const { children, value, index, adminView, ...other } = props;
  const classes = useStyles();

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      <Box height="100%" p={3} className={clsx(adminView ? classes.boxStyleAdmin : classes.boxStyleOperator)}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function OperatorNavigation(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0); 
  const [updateList, setUpdate] = useState(false);
  const { username, adminView } = props;
  const backendURL=`${process.env.REACT_APP_BACKEND_URL}`
  const infoProvided = { username: username, backendURL: backendURL };//Info sent to context

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 2) {
      setUpdate(true);
    } else {
      setUpdate(false);
    }
    console.log(window.innerWidth);
  };

  return (
    <OperatorProvider value={infoProvided}>
      <Paper 
        className={classes.root}
        elevation={10}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          centered
          {... (window.innerWidth < 600 ? {centered:false} : {centered:true})}
          {... (window.innerWidth < 600 ? {variant:"scrollable"} : {variant:'standard'})}
          scrollButtons="auto"
          className={classes.tabStyle}
        >
          <Tab label="Ventas" />
          <Tab label="Devoluciones" />
          <Tab label="Reportes" />
          <Tab label="Cierres" />
        </Tabs>
        <TabPanel value={value} index={0} adminView={adminView}>
          <Ventas backendURL={backendURL} username={username} adminView={adminView}/>
        </TabPanel>
        <TabPanel value={value} index={1} adminView={adminView}>
          <Devoluciones backendURL={backendURL} username={username} />
        </TabPanel>
        <TabPanel value={value} index={2} adminView={adminView}>
          <Reportes backendURL={backendURL} updateList={updateList} username={username} />
        </TabPanel>
        <TabPanel value={value} index={3} adminView={adminView}>
          <Cierres backendURL={backendURL} username={username} />
        </TabPanel>
      </Paper>
    </OperatorProvider>
  );
}