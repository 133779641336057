import Axios from 'axios';

const updateProductInventory = async (backendURL, ticketNumber, action, product) => {
  console.log(`Updating stocks. We're going to ${action} products!`);
  if (action === 'sell' || action === 'Sell') {
    var listItems = await Axios.get(`${backendURL}/operations?receipt=${ticketNumber}`);
    listItems = listItems.data;
    console.log(listItems)
    var i = 0;
    while (i < listItems.length) {
      sellProduct(backendURL, listItems[i].PLU, listItems[i].qty);
      i++;
    }
  } else if (action === 'return' || action === 'Return') {
    returnProduct(backendURL, product.PLU, product.qty)
  }

}

const sellProduct = async (backendURL, pluCode, qty) => {
  console.log('Updating Stocks. Sell');
  // var result = await Axios.put(`${backendURL}/products/stock?plu=${pluCode}`, {
  await Axios.put(`${backendURL}/products/stock?plu=${pluCode}`, {
    qty: -qty
  });
  // console.log(result);
}

const returnProduct = async (backendURL, pluCode, qty) => {
  console.log('Updating Stocks. Return');
  // var result = await Axios.put(`${backendURL}/products/stock?plu=${pluCode}`, {
  await Axios.put(`${backendURL}/products/stock?plu=${pluCode}`, {
    qty: qty
  });
  // console.log(result);
}

export default updateProductInventory;