import React, { useCallback } from 'react';
import SpecButton from '../Ventas/SpecialFunctionButton';
import {makeStyles} from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    whiteSpace: 'nowrap',
    overflowX: 'scroll',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }
}))

function SpecialFunctionButtonsContainer(props) {
  const classes = useStyles();
  const { updateShoppingCart } = props;

  const updateSC = useCallback(() => {
    updateShoppingCart();
  },
    [updateShoppingCart]
  );

  return (
    <div className={classes.buttonContainer}>
      <SpecButton nameFunction='RF' updateShoppingCart={updateSC} />
      <SpecButton nameFunction='Error' updateShoppingCart={updateSC} />
      <SpecButton nameFunction='%' />
      <SpecButton nameFunction='Buscar' />
    </div>
  );
}

export default SpecialFunctionButtonsContainer;