import React, { useState, useEffect, memo } from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchLabels = memo((props) => {
  const [isWheighted, setIsWheighted] = useState(null);
  const changeProductType = props.change;
  const { disabled } = props;

  useEffect(() => {
    // console.log(props.value);
    setIsWheighted(props.value)
  }, [isWheighted, props.value])

  const handleChange = event => {
    setIsWheighted(event.target.checked);
    // console.log(event.target.checked)
    changeProductType()
  };

  return (
    <div>
      {isWheighted !== null ? (
        <FormGroup row>
          <FormControlLabel
            control={
              <Switch
                checked={isWheighted}
                onChange={handleChange}
                color="primary"
                disabled={disabled}
              />
            }
            label={isWheighted ? "Pesado" : "Unitario"}
          />
        </FormGroup>
      ) : null}
    </div>
  );
})

export default SwitchLabels