import axios from 'axios';
import receiptData from './getChosenReceiptInfo';

const updateCashier =  async (backendURL, amount, payMethod, action, receipt) =>{
  // var currentCashierInfo = await axios.get(`${backendURL}/features/methods`);
  // currentCashierInfo = currentCashierInfo.data[0].cashierCurrent;
  if(action === 'sell'){
    console.log(`We gonna ${action} a product. Amount: ${amount}, Method: ${payMethod}`)
    await axios.put(`${backendURL}/features/updateCurrentCashier`,{
      method: payMethod,
      amount: amount 
    })
  }
  else if(action === 'return'){
    var method = await receiptData(backendURL, receipt);
    method = method.paymentMethod
    console.log(`We gonna ${action} a product in receipt ${receipt}. Amount: ${amount}, Method: ${method}`);
    await axios.put(`${backendURL}/features/updateCurrentCashier`,{
      method: method,
      amount: -amount 
    })
  }
}
export default updateCashier;