import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import { Grid, TextField, MenuItem, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
import {DialogTitle, Button} from '@material-ui/core'
import Switch from './SwitchModal';
import departments from 'data/departments';

const depts = departments();

export default function FormPropsTextFields(props) {
  const { classes, id, edit, action } = props;
  const [options, setOptions] = useState([]);
  const initialValues = ({
    name: "",
    department: "",
    PLU: "",
    barcode: "",
    price: "",
    cost: "",
    stock: "",
    isWeighted: null,
  })
  const [prevValues, setPrevValues] = useState(null);
  const [values, setValues] = useState(initialValues);
  const [updateProduct, setUpdateProduct] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [showPriceWarning, setshowPriceWarning] = useState(false);
  const [priceWarningModal, setPriceWarningModal] = useState(false);
  const [productSaved, setProductSaved] = useState(false);

  //----------------------- Update Product ------------------------
  const updateCurrentProduct = useCallback(async () => {
    handleClosePriceWarningModal();
    if (JSON.stringify(prevValues) !== JSON.stringify(values)) { //If false, hay cambios realizados
      if ((values.name === null) || (values.department === null) || (values.PLU === null) || (values.barcode === null) || (values.price === null) || (values.cost === null) || (values.stock === null) || (values.status === null) || (values.isWeighted === null)) {
        console.log('Null values are not valid. Please try again!');
        console.log(values);
      }
      else {
        // console.log('Make the request');
        const res = await Axios.put(`${process.env.REACT_APP_BACKEND_URL}/products/${id}`, {
          name: values.name,
          department: values.department,
          PLU: values.PLU,
          barcode: values.barcode,
          price: parseInt(values.price) || 0,
          cost: parseInt(values.cost) || 0,
          stock: values.stock,
          isWeighted: values.isWeighted,
          status: values.status
        });
        if (res.data === "Product updated succesfully.") {
          // console.log('Cerrar Loader');
          setPrevValues(values)
          setProductSaved(true);
        } else {
          console.log("Error: ", res.data);
          const timer = setTimeout(() => { handleCloseLoadModal() }, 15000)
          return () => clearTimeout(timer)
        }
      }
    }
  },[id, prevValues, values])

  //----------------------- Submit Handler --------------------------
  const handleSubmit = useCallback(async () => {
    setHideCircularProgress(false);
    if (values.price < values.cost) {
      setPriceWarningModal(true);
    } else {
      updateCurrentProduct();
    }
  },[updateCurrentProduct, values.price, values.cost])

  //----------------------- Events Handler ---------------------------
  //Used to check if user is editing or saving product info.
  useEffect(() => {
    const fetchData = () => {
      setUpdateProduct(edit); //Edit Product Info
      if (action === 'save') {
        handleSubmit();
      }
    }
    fetchData()
  }, [edit, action, handleSubmit])

  //----------------------- Departments Load --------------------------
  //Used to load departments in database
  useEffect(() => {
    const fetchData = async () => {
      setOptions(await depts);
    };
    fetchData();
  }, [])

  //----------------------- Product Features Load ----------------------
  useEffect(() => {
    const dataFetch = async () => {
      const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/${id}`)
      setValues(res.data)
      setPrevValues(res.data)
    };
    if (id) {
      dataFetch();
    }
  }, [id])

  //----------------------- Values Refresh --------------------------
  useEffect(() => {
    // console.log(values);
    values.price < values.cost ? setshowPriceWarning(true) : setshowPriceWarning(false)
  }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('name'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('department'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('PLU'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('barcode'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('price'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('cost'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('stock'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('isWeighted'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
      default:
        break;

    }
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (productSaved) {
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
  }, [productSaved])

  //----------------------- Price Warning Modal -----------------------
  const handleClosePriceWarningModal = () => {
    setPriceWarningModal(false);
  }
  

  return (
    <form autoComplete="off" onSubmit={handleSubmit} >
      <Grid container justify='center' spacing={2}>
        <Grid item xs={11} sm={12}>
          <TextField
            fullWidth
            required
            id="outlined-search1"
            value={values.name}
            onChange={handleChange('name')}
            label="Nombre del Producto"
            type="search"
            variant="outlined"
            disabled={!updateProduct}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="standard-select-department"
            select
            label="Departamento"
            value={values.department}
            onChange={handleChange('department')}
            variant="outlined"
            disabled={!updateProduct}
          >
            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search2"
            value={values.PLU}
            onChange={handleChange('PLU')}
            label="PLU"
            type="number"
            variant="outlined"
            disabled={!updateProduct}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search3"
            value={values.barcode}
            onChange={handleChange('barcode')}
            label="Código de Barras"
            type="number"
            variant="outlined"
            disabled={!updateProduct}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <div id="newProductPrc" className={clsx(classes.div, showPriceWarning && classes.divWarning)}>
            <TextField
              fullWidth
              required
              id="outlined-search4"
              value={values.price}
              onChange={handleChange('price')}
              label="Precio"
              type="number"
              variant="outlined"
              disabled={!updateProduct}
            />
          </div>
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search5"
            value={values.cost}
            onChange={handleChange('cost')}
            label="Costo"
            type="number"
            variant="outlined"
            disabled={!updateProduct}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search6"
            value={values.stock}
            onChange={handleChange('stock')}
            label="Stock"
            type="number"
            variant="outlined"
            disabled={!updateProduct}
            InputProps={{
              inputProps: { min: 0, max: 1000 }
            }}
          />
        </Grid>
        <div id="priceCostWarning" className={clsx(classes.fullWidth, showPriceWarning && classes.show, !showPriceWarning && classes.noShow)}>
          El precio del producto debe ser mayor que el costo del mismo!
      </div>
        {values.isWeighted !== null ? (<div id="switchComponent">
          <Switch
            change={handleChange('isWeighted')}
            disabled={!updateProduct}
            value={values.isWeighted} />
        </div>) : null}
      </Grid>
      <div id="loadingModal">
        <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
          <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
            <CircularProgress color="primary" size={190} />
          </DialogActions>
        </Dialog>
      </div>
      <div id="priceWarningModalModal">
        <Dialog open={priceWarningModal} onClose={handleClosePriceWarningModal}>
          <DialogTitle>
            Está seguro que desea continuar? El precio de venta debe ser mayor al costo del producto.
          </DialogTitle>
          <DialogActions>
            <Button onClick={updateCurrentProduct}> SI </Button>
            <Button onClick={handleClosePriceWarningModal}> NO </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}
