import React/*, {useState, useEffect}*/ from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const useStyles = makeStyles((theme) => ({
  modal: {
    margin: '10vh auto',
    width: '50vw',
    height: '80vh',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  h1: {
    color: "#000000",
    textAlign: "center",
  }
}));

const DiscountModal = (props) => {
  const classes = useStyles();
  // const { backendURL } = props;
  const openModal = props.open;
  const closeModal = props.close;

  return (
    <Modal open={openModal} onClose={closeModal}>
      <div className={classes.modal}>
        <h1 className={classes.h1}>Descuentos</h1>

      </div>
    </Modal>
  );
};

export default DiscountModal;