import React, {Component} from 'react';
import {Avatar, Button, CssBaseline, TextField, Link, Paper, 
Box, Grid, Typography} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
//import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withStyles, createMuiTheme } from '@material-ui/core/styles';

// ------------------------- HTTP Requests to backend ----------------------------
import axios from "axios";

// ----------------------------- Redirect Component -----------------------------
import Redirection from './RedirectToRole'; 

//Footer Copiright Link
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Altronics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Styles
const theme = createMuiTheme();
const styles = {
  root: {
    height: '100vh',
  },
  image: {
    // backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundImage:'url(https://i.imgur.com/Ba7qXt2.jpg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  h1:{
    color: "black"
  }
};

class SignInSide extends Component{
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      role:'',
      isAuthenticated: false,
      // backendURL: this.props.backendURL,
      backendURL: `${process.env.REACT_APP_BACKEND_URL}`
    }
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  //Events Handlers
  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
    //console.log(event.target.name, event.target.value) //Check Textfields input changes
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    const res = await axios.post(this.state.backendURL+"/users/login", {username: this.state.username, password:this.state.password});
    // console.log(res.data);
    if(res.data !== null){
      console.log("Autenticado. Rol: " + res.data.role);
      this.setState({
        role: res.data.role,
        isAuthenticated: true
      });
    }else{
      console.log("Credenciales erroneas!");
    }
    //console.log(res);
    //console.log(this.state)
  }

  render(){
    const { classes } = this.props;
    return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className={classes.h1}>
              Ingreso
            </Typography>
            <form className={classes.form} onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuario"
                name="username"
                autoComplete="username"
                autoFocus
                onChange={this.handleInputChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                onChange={this.handleInputChange}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                size="large"
              >
                Ingresar
              </Button>
              <Box mt={5}>
                <Copyright />
              </Box>
            </form>
          </div>
        </Grid>
        {
          this.state.role !== '' ? (
            <Redirection username={this.state.username} role={this.state.role} isAuthenticated={this.state.isAuthenticated} backendURL={this.state.backendURL}/>
          ) : null
        }
      </Grid>
    );
  }
}

SignInSide.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SignInSide);