import Axios from 'axios';
import updateStock from './updateProductInventory';
import updateCashier from './updateCashier';

const manageProductInReceipt = async (backendURL, barcode, action, currentList) => {
  var result;
  if (barcode.length === 12) { //Barcode complete - 12 numbers
    console.log(`Barcode complete, let´s search and ${action} item: ${barcode}`);
    var i = 0;
    while (i < currentList.length) {
      if (currentList[i].barcode === barcode) { //Barcode match
        console.log(`Item [${i}] barcode (${currentList[i].barcode}) matches with ${barcode}. Let´s ${action} it! Id code is ${currentList[i]._id}.`); //Printing database object _id
        switch (action) {
          case 'delete':
            await Axios.delete(`${backendURL}/operations?id=${currentList[i]._id}`)
            break;
          case 'return':
            result = await Axios.put(`${backendURL}/operations?id=${currentList[i]._id}`);
            updateStock(backendURL, currentList[i].receipt, 'return', currentList[i]);
            updateCashier(backendURL, currentList[i].price, null, 'return', currentList[i].receipt)
            result = result.data;
            break;
          default:
            console.log('Wrong action selected!');
        }
        i = currentList.length; //Break process
      }
      else {
        console.log(`Item [${i}] barcode (${currentList[i].barcode}) is not the same as ${barcode}.`);
      }
      i = i + 1;
    }
  }
  return result;
};

export default manageProductInReceipt;