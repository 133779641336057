import React, {useState, memo} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchLabels = memo((props) => {
  const [creditAvailable, setCreditAvailable] = useState(false);
  const changeCreditType = props.change;

  const handleChange = event => {
    setCreditAvailable(event.target.checked);
    // console.log(event.target.checked)
    changeCreditType()
  };

  return (
    <div>
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={creditAvailable}
            onChange={handleChange}
            color="primary"
          />
        }
        label={creditAvailable ? "Con Crédito" : "Sin Crédito"}
      />
      </FormGroup>
    </div>
  );
})

export default SwitchLabels