import React, { memo, useEffect, useState, useLayoutEffect } from 'react';
import clsx from 'clsx'
// import Axios from 'axios';
import { Dialog, Button, Collapse, Grid } from '@material-ui/core'
import Form from './itemModalWindow/FormModal'
import screenBreakpoint from 'utils/getScreenBreakpoint';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import BarChartIcon from '@material-ui/icons/BarChart';
// import AddIcon from '@material-ui/icons/Add';
import Toolbar from '../Toolbar';

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      // console.log(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const AlertDialog = memo((props) => {
  const { classes } = props;
  const { open, close } = props;
  const { id } = props;
  const [editProduct, setEditProduct] = useState(false);
  const [productInfoAction, setProductInfoAction] = useState('init');
  const [showStats, setShowStats] = useState(false);
  const [screenSize, setScreenSize] = useState('xs');
  const width = useWindowSize();

  useEffect(() => {
    setScreenSize(screenBreakpoint());
  }, [width])

  const handleClose = () => {
    close();
    setEditProduct(false);
    setProductInfoAction('init')
    // console.log('Cerrar')
  };

  //---------------------- Edit Button ----------------------
  const handleEdit = () => {
    setEditProduct(!editProduct)
    setProductInfoAction(!editProduct ? 'edit' : 'save') //Edit mode or save mode passed to form
  }

  //---------------------- Stats Button ----------------------
  const handleStats = () => {
    setShowStats(!showStats)
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        {...(screenSize === 'xs' ? { fullScreen: true } : { maxWidth: 'sm' })}
        disableBackdropClick
        className={classes.root}
        >
        <Toolbar close={handleClose} title={'Descripción'}/>
        <Form id={id} edit={editProduct} action={productInfoAction} classes={classes}/>
        <Collapse in={showStats} timeout={{ enter: 1000, exit: 1000 }}>
          <div className={clsx(classes.showStats)}>
            STATS
              </div>
        </Collapse>
        <div className={classes.buttonContainer}>
        <Grid container spacing={1} justify='center'>
          <Grid item xs={10} sm={6}>
            <Button
              fullWidth
              variant={editProduct ? "contained" : "outlined"}
              type="submit"
              color="secondary"
              size="large"
              startIcon={editProduct === false ? (<EditIcon />) : (<SaveIcon />)}
              onClick={handleEdit}
            >
              {editProduct === false ? ("Editar") : ("Guardar")}
            </Button>
          </Grid>
          {/* </div> */}
          {/* <div id="statistics" className={clsx(classes.button)}> */}
          {(screenSize !== 'xs' && screenSize !== 'sm' ? (
            <Grid item xs={10} sm={6}>
              <Button
                fullWidth
                variant={showStats ? "contained" : "outlined"}
                type="submit"
                color="secondary"
                size="large"
                startIcon={<BarChartIcon />}
                onClick={handleStats}
              >Estadisticas</Button>
            </Grid>
          ) : null)}
        </Grid>
        {/* </div> */}
        </div>
      </Dialog>
    </div>
  );
})

export default AlertDialog;