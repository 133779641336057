export default function useWindowSize() {
  var size = 0;
  const screenBreakpoints = [
    { label: 'xs', value: 0 },
    { label: 'sm', value: 600 },
    { label: 'md', value: 960 },
    { label: 'lg', value: 1280 },
    { label: 'xl', value: 1920 },
  ];

  function updateSize() {
    size = window.innerWidth;
    // console.log(window.innerWidth);
  }
  updateSize()

  var i = 0;
  var screenSize = 'xs'
  while (i < screenBreakpoints.length) {
    if (size < screenBreakpoints[i].value) {
      screenSize = screenBreakpoints[i-1].label;
      i = screenBreakpoints.length
    }
    i++
  }
  // return size;
  return screenSize;
}