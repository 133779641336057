import axios from 'axios';
import listReceipt from './fetchProductsInChosenReceipt'

const fnt = async (backendURL, receipt) => {
  var list = await listReceipt(backendURL, receipt);
  console.log(list);
  var initialLength = list.length; 

  var filtered = list.filter(item => item.status !== 'returned')
  console.log(filtered);
  var finalLength = filtered.length;
  
  var status = 'active';

  if(finalLength === initialLength){
    console.log('All products are active. Receipt Status: active');
    status = 'active'
  }else if(finalLength === 0){
    console.log('No products actives. Receipt Status: canceled');
    status = 'canceled';
  }else if(finalLength < initialLength){
    console.log('Some products are active. Receipt Status: some');
    status = 'some';
  }
  axios.put(`${backendURL}/closedReceipts/${receipt}`,{
    status: status
  })
}
export default fnt;