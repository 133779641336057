import React, { memo } from 'react'
import {makeStyles} from '@material-ui/core/styles';
import {Typography} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: "50px",
    color: "#FFF",
    textAlign: "center",
    width: "100%",
    display: "flex", 
    alignContent: "center", 
    position: "relative",
    borderRadius: '0 0 10px 10px',
    boxShadow: "0 3px 10px 3px rgba(0,0,0,0.3)",
    marginBottom: theme.spacing(1),
  },
  Toolbar: {
    margin: "auto",
    cursor: "default"
  },
  CloseButton: {
    position: "absolute",
    height: "50px",
    width: "45px",
    right: 0,
    bottom: -10,
    cursor: "pointer"
  }
}))

const Toolbar = memo((props) => {
  const classes = useStyles();
  const {close, title} = props;

  return (
    <div className={classes.root}>
      <Typography 
        component="h2"
        variant='h5' 
        align='center'
        className={classes.Toolbar}
      > 
        {title}
      </Typography>
      <Typography
        component="h2"
        variant="h5"
        className={classes.CloseButton}
        onClick={close}
      >
        X
      </Typography>
    </div>
  )
})

export default Toolbar;