import React, { useCallback, useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
  Paper,
} from '@material-ui/core'
import rowsData from './data';
import EnhancedTableHead from './EnhancedTabledHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import ItemModalWindow from './ModalWindow';
import AddModalWindow from './AddModalWindow';
import { stableSort, getSorting } from 'utils/tableSorting'

export default function ProductsList(props) {
  const { classes } = props;
  const [isMounted, setIsMounted] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRowsData] = useState([]);
  const [openItemModal, setItemModal] = useState(false)
  const [productId, setProductId] = useState('')
  const [openAddModal, setAddModal] = useState(false)
  const [productStock, setProductStock] = useState({
    weighted: 0,
    units: 0
  })

  //--------------------- Load Rows Data ---------------------
  useEffect(() => {
    const dataFetch = async () => {
      // console.log(await rowsData());
      setRowsData(await rowsData())
      setIsMounted(true);
    };
    dataFetch();
    return () => setIsMounted(false)
  }, [])

  useEffect(() => {
    if (rows.length !== 0) {
      let weighted = 0;
      let units = 0;
      rows.map(product => {
        return product.productType === 'Pesado' ? weighted += product.amount : units += product.amount
      })
      setProductStock({ weighted, units })
      // console.log(weighted, units);
    }
  }, [rows])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  //----------------- Modal Control ---------------
  const handleCloseModal = useCallback(async () => {
    setRowsData(await rowsData())
    setItemModal(false);
    setAddModal(false);
    // console.log('Cerrando Modal');
  },[])

  const handleClick = (event, id) => {
    setProductId(id)
    setItemModal(true);
    // console.log(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectedFieldChange = useCallback(async (data) => {
    // setSearchFields(data);
    setRowsData(await rowsData(data))
    // console.log(data);
  }, [])

  const handleOpenAddModal = useCallback(() => {
    setAddModal(true);
  }, [])

  return (
    <div className={classes.root}>
      {isMounted ? (<div>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            fieldsChange={handleSelectedFieldChange}
            openAddModal={handleOpenAddModal}
            productStock={productStock}
            classes={props.enhTableToolbarClasses}
          />
          <TableContainer>
            <Table
              className={classes.table}
              size='small'
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {rows.length >= 1 ? (
                  stableSort(rows, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onDoubleClick={event => handleClick(event, row.id)}
                          price="checkbox"
                          tabIndex={-1}
                          key={row.name}
                        >
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="center">{row.productStatus}</TableCell>
                          <TableCell align="center">{row.productType}</TableCell>
                          <TableCell align="center">{row.PLU}</TableCell>
                          <TableCell align="center">{row.barcode}</TableCell>
                          <TableCell align="center">{row.amount}</TableCell>
                          <TableCell align="center">{row.price}</TableCell>
                          <TableCell align="center">{row.cost}</TableCell>
                          <TableCell align="center">{row.sold}</TableCell>
                          {/* <TableCell align="center" className="iconCell"><EditIcon /></TableCell> */}
                        </TableRow>
                      );
                    })) : null}
              </TableBody>
            </Table>
          </TableContainer>
          {rows.length > 10 ?
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length || 1}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
            : null
          }
        </Paper>
        <ItemModalWindow open={openItemModal} close={handleCloseModal} id={productId} classes={props.itemModalClasses}/>
        <AddModalWindow open={openAddModal} close={handleCloseModal} classes={props.itemModalClasses}/>
      </div>
      ) : null}
    </div>
  );
}