const redirectToLoginPage = (localkey) => {
    // var localKey = props.localKey;
    var userInfo = JSON.parse(localStorage.getItem(localkey))
    const {isAuth} = userInfo;
    // console.log(isAuth);
    if(!isAuth){
      window.location = '/'
    }
}

export default redirectToLoginPage;
