import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import { Grid, TextField, MenuItem, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
import rolesOptions from 'data/roles';

export default function FormPropsTextFields(props) {
  const { classes, id, edit, action } = props;
  const initialValues = ({
    name: "",
    id: "",
    address: "",
    cellphone: "",
    whatsapp: "",
    email: "",
    username: "",
    password: "",
    role: "operator",
    status: true
  })
  const [prevValues, setPrevValues] = useState(initialValues);
  const [values, setValues] = useState(initialValues)
  const [updateUser, setUpdateUser] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [userSaved, setUserSaved] = useState(false);

    //----------------------- Update User ------------------------
    const updateCurrentUser = useCallback(

      async () => {
        if (JSON.stringify(prevValues) !== JSON.stringify(values)) { //If false, hay cambios realizados
          const res = await Axios.put(`${process.env.REACT_APP_BACKEND_URL}/users/${id}`, {
            name: values.name,
            id: values.id,
            address: values.address,
            cellphone: values.cellphone,
            whatsapp: values.whatsapp,
            email: values.email,
            username: values.username,
            password: values.password,
            role: values.role,
            status: values.status,
          });
          
          if (res.data === "User updated successfully.") {
            // console.log('Cerrar Loader');
            setPrevValues(values)
            setUserSaved(true);
          } else {
            console.log("Error: ", res.data);
            const timer = setTimeout(() => { handleCloseLoadModal() }, 10000)
            return () => clearTimeout(timer)
          }
        }
      }, [id, prevValues, values]
    )
  
    //----------------------- Submit Handler --------------------------
    const handleSubmit = useCallback(
      async (event) => {
        setHideCircularProgress(false);
        updateCurrentUser();
      }, [updateCurrentUser]
    ) 

  //----------------------- Events Handler ---------------------------
  //Used to check if user is editing or saving product info.
  useEffect(() => {
    const fetchData = () => {
      setUpdateUser(edit); //Edit User Info
      if (action === 'save') {
        handleSubmit();
      }
    }
    fetchData()
  }, [edit, action, handleSubmit])

  //----------------------- Customer Features Load ----------------------
  useEffect(() => {
    const dataFetch = async () => {
      const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/${id}`)
      setValues(res.data)
      setPrevValues(res.data)
    };
    if (id) {
      dataFetch();
    }
  }, [id])

  //----------------------- Values Refresh --------------------------
  // useEffect(() => {
  //   console.log(values);
  // }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('name'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('username'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('id'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('address'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('cellphone'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('whatsapp'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('email'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('password'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('role'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('status'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
        default:
          break;
          
        }
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
    setUserSaved(false);
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (userSaved) {
      setHideCircularProgress(false);
      setLoadModal(true);
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
  }, [userSaved])


  return (
    <form autoComplete="off" onSubmit={handleSubmit} >
      <Grid container justify='center' spacing={2}>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search1"
            value={values.name}
            onChange={handleChange('name')}
            label="Nombre del Empleado"
            type="search"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search10"
            value={values.username}
            onChange={handleChange('username')}
            label="Usuario"
            type="search"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="standard-select-department"
            className={classes.shadow}
            value={values.id}
            onChange={handleChange('id')}
            label="Identificación"
            type="search"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search2"
            value={values.address}
            onChange={handleChange('address')}
            label="Dirección"
            type="string"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search3"
            value={values.cellphone}
            onChange={handleChange('cellphone')}
            label="Telefono"
            type="number"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search4"
            value={values.whatsapp}
            onChange={handleChange('whatsapp')}
            label="Whatsapp"
            type="number"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            className={classes.shadow}
            id="outlined-search5"
            value={values.email}
            onChange={handleChange('email')}
            label="Email"
            type="email"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            className={classes.shadow}
            id="outlined-search6"
            value={values.password}
            onChange={handleChange('password')}
            label="Contraseña"
            type="password"
            variant="outlined"
            disabled={!updateUser}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            select
            className={classes.shadow}
            id="outlined-search7"
            value={values.role}
            onChange={handleChange('role')}
            label="Rol"
            variant="outlined"
            InputProps={{
              inputProps: { min: 0, max: 1000 }
            }}
            disabled={!updateUser}
          >
            {rolesOptions.map(role => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        </Grid>
        <div id="loadingModal">
          <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
            <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
              <CircularProgress color="primary" size={190} />
            </DialogActions>
          </Dialog>
        </div>
    </form>
      );
    }
