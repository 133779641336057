import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, Box, Grid, TextField } from '@material-ui/core';
import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import StoreIcon from '@material-ui/icons/Store';
import clsx from 'clsx';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function CustomerField(props) {
  const { customerData, title, field, deliveryInfoUpdated, ...other } = props;

  const handleChange = (fieldName) => event => {
    deliveryInfoUpdated(fieldName, event.target.value)
  }
  return (
    <TextField
      fullWidth
      variant='outlined'
      label={title}
      value={customerData[field]}
      onChange={handleChange(field)}
      {...((customerData._id) ? { disabled: false } : { disabled: true })}
      {...other}
    />
  )
}


const useStyles = makeStyles((theme) => ({
  main:{
    height: "calc(100% - 54px)",
    display: 'flex',
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    '& .MuiTab-wrapper': {
      flexDirection: 'row'
    },
    '& .MuiTabs-scroller .MuiButtonBase-root .MuiTab-wrapper svg': {
      marginLeft: '0px',
      marginRight: '4px',
      textAlign: 'left',
    },
  },
  storePickup:{
    minHeight: '100%',
    display: "flex",
    justifyContent: "center",
    '& .MuiBox-root':{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
  },
  deliveryRoot: {
    height: '100%',
    '& .MuiBox-root:first-child': {
      padding: '16px 8px 0px 12px',
    },
  },
  customerInfoContainer: {
    paddingTop: '0px',
    maxHeight: '180px',
    overflowY: 'auto',
    overflowX: 'hidden'
  },
}));

export default function FullWidthTabs(props) {
  const { customerInfo, deliveryInfoUpdate } = props;
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [customerData, setCustomerData] = useState(customerInfo);
  
  // UPDATE CUSTOMER INFO AS PARENT CHANGE IT
  useEffect(() => {
    const updateCustomer = () => {
      setCustomerData(customerInfo)
    };
    updateCustomer()
  }, [customerInfo])

  // HANDLE TABS CHANGE
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const deliveryCheck = () => {
      if(value){
        deliveryInfoUpdate(customerData)
      }else{
        deliveryInfoUpdate(null)
      }
    }
    deliveryCheck();
  }, [value, customerData])

  const updateDeliveryInfo = (fieldName, fieldValue) => {
    setCustomerData({ ...customerData, [fieldName]: fieldValue })
  }

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Tienda" icon={<StoreIcon />} />
        <Tab
          label="Domicilio" icon={<MotorcycleIcon />}
          {...(customerInfo._id ? { disabled: false } : { disabled: true })}
        />
      </Tabs>
      <main className={classes.main}>
        <TabPanel value={value} index={0} className={classes.storePickup}>
            <Typography variant='h5' align='center'>Pedido a ser recogido en nuestra tienda</Typography>
        </TabPanel>
        <TabPanel value={value} index={1} className={classes.deliveryRoot}>
          <Box className={clsx('hideScrollbar', classes.customerInfoContainer)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomerField
                  customerData={customerData}
                  title='Cliente'
                  field='name'
                  deliveryInfoUpdated={updateDeliveryInfo} />
              </Grid>
              <Grid item xs={6}>
                <CustomerField
                  customerData={customerData}
                  title='Identificación'
                  field='id'
                  deliveryInfoUpdated={updateDeliveryInfo} />
              </Grid>
              <Grid item xs={6}>
                <CustomerField
                  customerData={customerData}
                  title='Telefono'
                  field='telephone'
                  deliveryInfoUpdated={updateDeliveryInfo} />
              </Grid>
              <Grid item xs={6}>
                <CustomerField
                  customerData={customerData}
                  title='Whatsapp'
                  field='whatsapp'
                  deliveryInfoUpdated={updateDeliveryInfo} />
              </Grid>
              <Grid item xs={6}>
                <CustomerField
                  customerData={customerData}
                  title='Dirección'
                  field='address'
                  deliveryInfoUpdated={updateDeliveryInfo} />
              </Grid>
            </Grid>
          </Box>
        </TabPanel>
      </main>
    </div>
  );
}