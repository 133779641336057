import React, { useState, useCallback } from 'react';
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DiscountIcon from 'icons/DiscountIcon'
import CashRegister from 'icons/CashRegister'
import GoalsIcon from 'icons/GoalsIcon'
import StockIcon from 'icons/StockIcon'

import DiscountModal from './modals/Discount'
import CashierModal from './modals/Cashier'
import GoalsModal from './modals/Goals'
import StockModal from './modals/Stock'

const useStyles = makeStyles(theme => ({
  button: {
    marginRight: theme.spacing(4),
    '& > span': {
      width: '100%',
      display: 'block'
    },
    '& > span > span': {
      width: '100%',
      display: 'block',
      margin: 'auto'
    }
  }
}))

export default function HelloTab() {
  const classes = useStyles();
  const [discountModal, setDiscountModal] = useState(false);
  const [cashierModal, setCashierModal] = useState(false);
  const [goalsModal, setGoalsModal] = useState(false);
  const [stockModal, setStockModal] = useState(true);

  //------------------------------Discount Modal------------------------------
  const handleClickDiscount = () => {
    setDiscountModal(true);
  }
  //------------------------------Cashier Modal------------------------------
  const handleClickCashier = () => {
    setCashierModal(true);
  }
  //------------------------------Goals Modal------------------------------
  const handleClickGoals = () => {
    setGoalsModal(true);
  }
  //------------------------------Stock Modal------------------------------
  const handleClickStock = () => {
    setStockModal(true);
  }

  // -------------------------- Close Modal ------------------------------
  const handleCloseModal = useCallback(() => {
    setDiscountModal(false)
    setCashierModal(false)
    setGoalsModal(false)
    setStockModal(false)
  }, [])

  return (
    <div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClickDiscount}
        startIcon={<DiscountIcon color="primary" style={{ fontSize: "9rem" }} />}
      >
        <Typography
          align="center"
          component="h2"
          variant="h5"
          display='block'
        >
          Descuentos
        </Typography>
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClickCashier}
        startIcon={<CashRegister color="primary" style={{ fontSize: "9rem" }} />}
      >
        <Typography
          align="center"
          component="h2"
          variant="h5"
          display='block'
        >
          Cajas
        </Typography>
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClickGoals}
        startIcon={<GoalsIcon color="primary" style={{ fontSize: "9rem" }} />}
      >
        <Typography
          align="center"
          component="h2"
          variant="h5"
          display='block'
        >
          Metas
        </Typography>
      </Button>
      <Button
        variant="contained"
        className={classes.button}
        onClick={handleClickStock}
        startIcon={<StockIcon color="primary" style={{ fontSize: "9rem" }} />}
      >
        <Typography
          align="center"
          component="h2"
          variant="h5"
          display='block'
        >
          Inventario
        </Typography>
      </Button>
      <DiscountModal open={discountModal} close={handleCloseModal} />
      <CashierModal open={cashierModal} close={handleCloseModal} />
      <GoalsModal open={goalsModal} close={handleCloseModal} />
      <StockModal open={stockModal} close={handleCloseModal} />
    </div>
  );
}
