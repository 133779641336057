import Axios from 'axios';
let weighted = 0;
let units = 0;


function createData(id, name, productStatus, productType, PLU, barcode, amount, price, sold, cost) {
  return { id, name, productStatus, productType, PLU, barcode, amount, price, sold, cost };
}

const data = async (searchParams) => {
  let rows = [];
  let productArr = [];
  var params = searchParams || { inputField: "", selectField: "name" }

  if (params.selectField === "name" && params.inputField.length >= 3) {
    // console.log(params)
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/regex?input=${params.inputField}&option=${params.selectField}`)
    // console.log(res.data);
    productArr = res.data;
  } else if (params.selectField === "PLU" && params.inputField.length >= 1) {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/regex?input=${params.inputField}&option=${params.selectField}`)
    productArr = res.data;
  } else if (params.selectField === "barcode" && params.inputField.length >= 5) {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/products/regex?input=${params.inputField}&option=${params.selectField}`)
    productArr = res.data;
  } else {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/products`);
    productArr = res.data;
  }

  productArr.map(product => {
    product.isWeighted ? weighted++ : units++;
    return rows.push(
      createData(product._id, product.name, product.status ? 'Activo' : 'Descontinuado', product.isWeighted ? 'Pesado' : 'Unidad',
        product.PLU, product.barcode, product.stock, product.price, parseInt(Math.random() * 100), product.cost)
    );

  })

  return rows;
};

export default data;