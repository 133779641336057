import React, {useState} from 'react';
import './App.css';

import Routes from "./Routes";

function App() {
  // const [backendURL] = useState("http://192.168.1.102:4000");
  // const [backendURL] = useState("http://jaltamar12.hopto.org:4000");
  // const [backendURL] = useState("http://178.128.154.67:4000");
  const [backendURL] = useState(`${process.env.REACT_APP_BACKEND_URL}`)
  
  const childProps = {
    backendURL: backendURL
  };    
  return (
    <div className="App">
      <Routes childProps={childProps}/>
    </div>
  );
}

export default App;
