import React, { memo, useEffect, useState, useLayoutEffect } from 'react';
import { Dialog, DialogActions } from '@material-ui/core'
import Form from './addModalWindow/FormModal'
import Toolbar from '../Toolbar'
import screenBreakpoint from 'utils/getScreenBreakpoint';

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      // console.log(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const AlertDialog = memo((props) => {
  const {classes} = props;
  const { open, close } = props;
  const [screenSize, setScreenSize] = useState('xs');
  const width = useWindowSize();

  useEffect(() => {
    setScreenSize(screenBreakpoint());
  }, [width])

  const handleClose = () => {
    close();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        {...(screenSize === 'xs' ? { fullScreen: true } : { maxWidth: 'xs' })}
      >
        <Toolbar close={handleClose} title={'Agregar Cliente'}/>
        <DialogActions className="flex-direction-column">
          <Form classes={classes}/>
        </DialogActions>
      </Dialog>
    </div>
  );
})

export default AlertDialog;