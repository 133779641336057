import React from 'react';
import { Redirect } from 'react-router-dom'

function RedirectToRole(props) {
  const {backendURL, username, role, isAuthenticated} = props;
  const path = `/${role}`;

  // console.log(path, username, isAuthenticated)

  const userInfo = {}
  userInfo.isAuth = isAuthenticated;
  userInfo.username = username; 
  localStorage.setItem('User Info', JSON.stringify(userInfo))

  return (
    <Redirect to={{
      pathname: path,
      state: {
        username,
        isAuthenticated,
        backendURL
      }
    }} />
    // <Redirect to={path}/>
  )
}

export default RedirectToRole;