import Axios from 'axios';

const sales = async (backendURL) => {
  var ops = await Axios.get(`${backendURL}/operations`)
  ops = ops.data
  // console.log(ops);
  var deps = await Axios.get(`${backendURL}/features/methods`)
  deps = deps.data[0].departments
  console.log(deps);
  // var acumByDepts = {}
  // deps.map(department => {
  //   return acumByDepts[department] = 0
  // })
  // ops.map(operation => {
  //   return acumByDepts[operation.department] += operation.price
  // })
  // // console.log(acumByDepts); //To show sales by departments. 
  // return acumByDepts;

  var acumByDepts = [];
  deps.map(department => {
    return acumByDepts.push(0);
  });
  console.log(acumByDepts);

  for(var i=0; i< ops.length; i++){
    for(var j= 0; j < deps.length; j++){
      if(ops[i].department === deps[j]){
        acumByDepts[j] += ops[i].price
      }
    }
  }
  return(acumByDepts)
}

export default sales;