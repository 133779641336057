import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import { Grid, TextField, Button, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
import Switch from './SwitchModal'

export default function FormPropsTextFields(props) {
  const {classes} = props;
  const initialValues = ({
    name: "",
    id: "",
    address: "",
    telephone: "",
    whatsapp: "",
    email: "",
    creditAmount: "",
    creditDeadlines: "",
    creditBalance: "",
    creditAvailable: false,
    status: true
  })
  const [values, setValues] = useState(initialValues)
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [customerSaved, setCustomerSaved] = useState(false);

  //----------------------- Values Refresh --------------------------
  // useEffect(() => {
  //   console.log(values);
  // }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('name'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('id'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('address'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('telephone'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('whatsapp'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('email'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('creditAmount'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('creditDeadlines'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('creditBalance'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('creditAvailable'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
      default:
        break;

    }
  }

  //----------------------- Submit Handler --------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    setHideCircularProgress(false);
    createNewCustomer();
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (customerSaved) {
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
    setCustomerSaved(false);
    // console.log(loadModal);
  }, [customerSaved])

  //----------------------- Create New Customer ------------------------
  const createNewCustomer = async () => {
    setLoadModal(true);
    const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/customers`, {
      name: values.name,
      id: values.id,
      address: values.address,
      telephone: values.telephone,
      whatsapp: values.whatsapp,
      email: values.email,
      creditAvailable: values.creditAvailable,
      creditDeadlines: values.creditDeadlines || 0,
      creditBalance: values.creditBalance || 0,
      creditAmount: values.creditAmount || 0,
      status: values.status
    });
    if (res.data === "Customer created") {
      // console.log('Cerrar Loader');
      setCustomerSaved(true);
      setValues(initialValues)
    } else {
      console.log("Error: ", res.data);
      const timer = setTimeout(() => { handleCloseLoadModal() }, 15000)
      return () => clearTimeout(timer)
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search1"
            value={values.name}
            onChange={handleChange('name')}
            label="Nombre del Cliente"
            type="search"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="standard-select-department"
            label="Identificación"
            value={values.id}
            onChange={handleChange('id')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search2"
            value={values.address}
            onChange={handleChange('address')}
            label="Dirección"
            type="string"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search3"
            value={values.telephone}
            onChange={handleChange('telephone')}
            label="Telefono"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search4"
            value={values.whatsapp}
            onChange={handleChange('whatsapp')}
            label="Whatsapp"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search5"
            value={values.email}
            onChange={handleChange('email')}
            label="Email"
            type="email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            id="outlined-search6"
            value={values.creditAmount}
            onChange={handleChange('creditAmount')}
            label="Cupo del Crédito"
            type="number"
            variant="outlined"
            disabled={!values.creditAvailable}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search7"
            value={values.creditDeadlines}
            onChange={handleChange('creditDeadlines')}
            label="Plazo del Crédito"
            type="number"
            variant="outlined"
            InputProps={{
              inputProps: { min: 0, max: 1000 }
            }}
            disabled={!values.creditAvailable}
          />
        </Grid>
        <Grid item xs={11}>
          <TextField
            fullWidth
            required
            id="outlined-search8"
            value={values.creditBalance}
            onChange={handleChange('creditBalance')}
            label="Balance del Crédito"
            type="number"
            variant="outlined"
            disabled={!values.creditAvailable}
          />
        </Grid>
        <Grid item xs={11}>

          <div id="switchComponent">
            <Switch change={handleChange('creditAvailable')} />
          </div>
        </Grid>
        <Grid item xs={11}></Grid>
        <div id='submitButton'>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
          >Crear Cliente</Button>
        </div>
      </Grid>
      <div id="loadingModal">
        <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
          <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
            <CircularProgress color="primary" size={190} />
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}
