import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Dialog, TextField, Button } from '@material-ui/core';

import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '& .MuiAppBar-colorPrimary': {
      backgroundColor: '#35DEBB',
      color: '#000',
    },
  },
  hidden: {
    display: 'none'
  },
  gridSpacingL: {
    padding: theme.spacing(2),
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    '& .MuiFormControl-marginNormal':{
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    }
  },
  gridSpacingR: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(0),
  },
}));

const SearchModal = (props) => {
  const classes = useStyles();
  const { backendURL } = props;
  const openModal = props.open;
  const closeModal = props.close;

  const [barcode, setBarcode] = useState('');
  const [searchItem, setSearchItem] = useState(false);
  const initValues = {
    name: '',
    barcode: '',
    price: '',
    department: '',
    stock: '',
    PLU: ''
  }
  const [values, setValues] = useState(initValues);

  useEffect(() => {
    const fetchProductInDB = async () => {
      if (searchItem && barcode.length === 12) {
        var result = await Axios.get(`${backendURL}/products/search?barcode=${barcode}`)
        if (result.data !== undefined) {
          setValues(result.data[0]);
          setBarcode('');
          setSearchItem(false);
        }
      }
    };
    fetchProductInDB();
  }, [searchItem, barcode, backendURL]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchItem(true);
    }
  }

  const closeModalAndReset = (event) => {
    closeModal();
    setValues(initValues)
  }

  return (
    <Dialog
      open={openModal}
      onClose={closeModalAndReset}
      className={classes.root}
      maxWidth='sm'
      fullWidth
    >
      <Toolbar title='Buscar Productos' close={closeModalAndReset} />
      <Grid container>
        <Grid
          item {...((values.name !== '') ? { xs: 6 } : { xs: 12 })}
          className={classes.gridSpacingL}>
            <TextField
              id="searchProductInput"
              label="Código de barras"
              type="search"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={barcode}
              onChange={(e) => { setBarcode(e.target.value) }}
              onKeyPress={handleKeyPress}
              fullWidth
              autoFocus
              required
              inputProps={{
                maxLength: 12
              }}
            />
            <Button
              type='button'
              variant='contained'
              size='large'
              onClick={() => { setSearchItem(true) }}
              {...((barcode === '') ? { disabled: true } : { disabled: false })}
            >
              Buscar Producto
          </Button>
        </Grid>
        <Grid
          item xs={12} sm={6}
          className={clsx(!values.name && classes.hidden, classes.gridSpacingR)}
        >
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Nombre del Producto"
            value={values.name} // eslint-disable-next-line
            margin="normal"
            variant="outlined"
          />
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Precio del Producto"
            value={_.round(values.price).toLocaleString('de-DE')} // eslint-disable-next-line
            margin="normal"
            variant="outlined"
          />
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Código PLU"
            value={values.PLU} // eslint-disable-next-line
            margin="normal"
            variant="outlined"
          />
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Código de barras"
            value={values.barcode} // eslint-disable-next-line
            margin="normal"
            variant="outlined"
          />
          <TextField
            InputProps={{ readOnly: true }}
            fullWidth
            label="Departamento"
            value={values.department} // eslint-disable-next-line
            margin="normal"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SearchModal;