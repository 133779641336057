export default function cashierNames(method){
  var name =  '';
  switch(method){
    case 'cash':
      name = 'Efectivo';
      break;
    case 'debit-card':
      name = 'Tarjeta Débito';
      break;
    case 'credit-card':
      name = 'Tarjeta Crédito';
      break;
    case 'credit-client':
      name = 'Crédito Cliente';
      break;
    default:
      //
  }
  return name;
}