import React from 'react'
import { SvgIcon } from '@material-ui/core'
export default function DiscountIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg id="Capa_1" enableBackground="new 0 0 512 512" height="24" viewBox="0 0 512 512" width="24" xmlns="http://www.w3.org/2000/svg"><path d="m511.975 376.505c-.008-.231-.017-.461-.035-.693-.026-.327-.066-.649-.113-.969-.019-.13-.023-.259-.045-.389l-30.782-178.735v-58.719c0-8.284-6.716-15-15-15h-85v-32h45c8.284 0 15-6.716 15-15v-60c0-8.284-6.716-15-15-15h-120c-8.284 0-15 6.716-15 15v60c0 8.284 6.716 15 15 15h45v32h-140v-107c0-8.284-6.716-15-15-15h-90c-8.284 0-15 6.716-15 15v107h-45c-8.284 0-15 6.716-15 15v58.719l-30.782 178.735c-.022.129-.026.259-.045.389-.046.321-.087.642-.113.969-.018.231-.027.462-.035.693-.005.166-.025.328-.025.495v120c0 8.284 6.716 15 15 15h482c8.284 0 15-6.716 15-15v-120c0-.167-.02-.329-.025-.495zm-190.975-316.505v-30h90v30zm-50 92h180v30h-180zm0 60h182.362l25.833 150h-208.195zm-60-60h30v30h-30zm-90-122h60v152h-60zm-60 122h30v30h-30zm-2.362 60h182.362v150h-208.196zm423.362 270h-452v-90h452z"/><circle cx="311" cy="257" r="15"/><circle cx="366" cy="257" r="15"/><circle cx="421" cy="257" r="15"/><circle cx="311" cy="317" r="15"/><circle cx="366" cy="317" r="15"/><circle cx="421" cy="317" r="15"/><path d="m76 452h260c8.284 0 15-6.716 15-15s-6.716-15-15-15h-260c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/><path d="m396 452h40c8.284 0 15-6.716 15-15s-6.716-15-15-15h-40c-8.284 0-15 6.716-15 15s6.716 15 15 15z"/></svg>
      {/* <svg style="width:24px;height:24px" viewBox="0 0 24 24"> */}
      {/* <path fill="currentColor" d="M2,17H22V21H2V17M6.25,7H9V6H6V3H14V6H11V7H17.8C18.8,7 19.8,8 20,9L20.5,16H3.5L4.05,9C4.05,8 5.05,7 6.25,7M13,9V11H18V9H13M6,9V10H8V9H6M9,9V10H11V9H9M6,11V12H8V11H6M9,11V12H11V11H9M6,13V14H8V13H6M9,13V14H11V13H9M7,4V5H13V4H7Z" /> */}
    </SvgIcon>
  )
}