import React from 'react';
import PropTypes from 'prop-types';

import {
  TableCell, TableHead, TableRow, TableSortLabel} from '@material-ui/core'

  const headCells = [
    { id: 'name', label: window.innerWidth >= 1184 ? 'Nombre del Empleado' : 'Empleado' },
    { id: 'clientStatus', label: 'Estado' },
    { id: 'username', label: 'Usuario' },
    { id: 'clientAddress', label: 'Dirección' },
    { id: 'whatsapp', label: 'Whatsapp' },
    { id: 'cellphone', label: 'Telefono' },
    { id: 'role', label: 'Rol' },
    { id: 'edit', label: 'Editar' },
    { id: 'unable', label: 'Deshabilitar' },
  ];

export default function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={'center'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};