import Axios from 'axios';

function createData(_id, id, name, clientStatus, address, telephone, whatsapp, email, creditAvailable, creditBalance, creditAmount, lastPurchase, lastPayment, nextPayment) {
  return { _id, id, name, clientStatus, address, telephone, whatsapp, email, creditAvailable, creditAmount, creditBalance, lastPurchase, lastPayment, nextPayment };
}

const data = async (searchParams) => {
  let rows = [];
  let customersArr = [];
  var params = searchParams || { inputField: "", selectField: "name" }

  if (params.selectField === "name" && params.inputField.length >= 2) {
    // console.log(params)
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/customers/regex?input=${params.inputField}&option=${params.selectField}`)
    // console.log(res.data);
    customersArr = res.data;
  } else if ((params.selectField === "id" || params.selectField === "telephone" || params.selectField === "whatsapp")
    && params.inputField.length >= 1) {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/customers/regex?input=${params.inputField}&option=${params.selectField}`)
    customersArr = res.data;
  } else {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/customers`);
    customersArr = res.data;
    // console.log(customersArr);
  }

  customersArr.map(customer => {
    return rows.push(
      createData(customer._id, customer.id, customer.name, customer.status, customer.address, customer.telephone, 
        customer.whatsapp, customer.email, customer.creditAvailable, customer.creditBalance, customer.creditAmount, customer.lastPurchase, customer.lastPayment, customer.nextPayment)
    );

  })
  return rows;
};

export default data;