import React/*, { Component }*/ from "react";
// import axios from "axios";

// import "CSS/operator.css"
import "./operator.css"

import Auth from "components/RedirectToLoginPage";
// import Navbar from "components/operator/OperatorNavigation";

const Maintenance = (props) => {
  return (
    <div>
    {(props.location.state !== undefined) ? (
      <div>
        <h2>Maintenance</h2>
      {/* <Navbar backendURL={props.location.state.backendURL} /> */}
      {/* <Auth isAuth={this.state.isAuth} /> Agregar isAuthenticated para mantener o redireccionar a login */}
    </div>
    ) : (
      <Auth isAuth={false}/>
    )}
    </div>
  )
}

export default Maintenance;
