import Axios from 'axios';

const fetchPlucCode = async (backendURL, pluCode) => {
  var result = '';
  backendURL=`${process.env.REACT_APP_BACKEND_URL}`
  switch (pluCode.length) {
    case 4:
      result = await Axios.get(`${backendURL}/products/search?plu=${pluCode}`);
      break;
    case 3:
      result = await Axios.get(`${backendURL}/products/search?plu=0${pluCode}`);
      break;
    case 2:
      result = await Axios.get(`${backendURL}/products/search?plu=00${pluCode}`);
      break;
    case 1:
      result = await Axios.get(`${backendURL}/products/search?plu=000${pluCode}`);
      break;
    default:
      //nothing
  }
  if (result.data !== undefined) {
    return(result.data[0]);
  }
}

export default fetchPlucCode;