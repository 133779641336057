import React, { useState, useEffect, Fragment } from 'react'
import clsx from 'clsx';
import {
    Dialog, Grid, TextField, Box, MenuItem, InputAdornment,
    Table, TableHead, TableBody, TableRow, TableCell, Tooltip,
    Typography, Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search';
import Toolbar from '../../Toolbar';
import data from 'components/admin/dashboard/customers/data';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    tableRoot: {
        minHeight: '300px',
        maxHeight: "300px",
        overflow: 'auto',
    },
    tableBody: {
        '& .MuiTableRow-root': {
            cursor: 'default',
        },
        '& .Mui-selected, & .Mui-selected:hover': {
            backgroundColor: 'rgba(71, 145, 219, 0.38)'
        },
    },
    selectButton: {
        display: 'flex',
        justifyContent: 'center',
    },
    verticalCenter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}))

const FindCustomer = (props) => {
    const openDialog = props.findCustomerDialog
    const closeDialog = props.closeFindCustomerDialog;
    const { setCustomer } = props;
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [value, setValue] = useState({
        inputField: '',
        selectField: 'name',
    });
    const [customersFound, setCustomersFound] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState({});

    // CHANGE IN SEARCH PARAMETERS
    const handleChange = (data) => event => {
        setValue({ ...value, [data]: event.target.value })
    }

    // SEARCH OPERATION
    useEffect(() => {
        const searchCustomer = async () => {
            if (value.inputField === '') {
                setCustomersFound(null);
            } else {
                setCustomersFound(await data(value));
            }
        }
        searchCustomer();
    }, [value])

    // CHECK AND SELECT CUSTOMER
    const handleClick = (event, item) => {
        if (selected === item.id) {
            setSelected([])
            setSelectedCustomer({});
        } else {
            setSelected(item.id);
            setSelectedCustomer(item)
        }
    };

    // HIGHLIGHT CUSTOMER SELECTED
    const isSelected = (name) => selected.indexOf(name) !== -1;

    //CUSTOMER INFO EXPORT TO PARENT AND CLOSE DIALOG
    const exportCustomerSelectedInfo = () => {
        if (selectedCustomer) {
            setCustomer(selectedCustomer);
            closeDialog();
        }
    };

    return (
        <Dialog
            open={openDialog}
            onClose={closeDialog}
            fullWidth
            maxWidth={'xs'}
        >
            <Toolbar title='Buscar Cliente' close={closeDialog} />
            {/* SEARCH BAR */}
            <Box p={1}>
                <Grid container>
                    <Grid item xs={12} sm={7}>
                        <TextField
                            autoFocus
                            id="toolbarSearchfield"
                            variant="outlined"
                            value={value.inputField}
                            onChange={handleChange('inputField')}
                            fullWidth
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                        />
                    </Grid>
                    <Grid item xs={9} sm={5}>
                        <TextField
                            id="toolbarOption"
                            value={value.selectField}
                            onChange={handleChange('selectField')}
                            select
                            variant='outlined'
                            fullWidth
                        >
                            <MenuItem value="name">Nombre</MenuItem>
                            <MenuItem value="id">Identificacion</MenuItem>
                            <MenuItem value="telephone">Telefono</MenuItem>
                            <MenuItem value="whatsapp">Whatsapp</MenuItem>
                        </TextField>
                    </Grid>
                </Grid >
            </Box>
            {/* CUSTOMERS FOUND CONTAINER */}
            <Box m={1} className={clsx(classes.tableRoot, 'hideScrollbar', !customersFound && classes.verticalCenter)}>
                {
                    customersFound
                        ? (<Table stickyHeader >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nombre</TableCell>
                                    <TableCell>Dirección</TableCell>
                                    <TableCell>Telefono</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableBody}>
                                {_.map(customersFound, (customer) => {
                                    const isItemSelected = isSelected(customer.id);
                                    return (
                                        <TableRow
                                            key={customer.id}
                                            onClick={(event) => handleClick(event, customer)}
                                            selected={isItemSelected}>
                                            <Tooltip title={<Fragment><Typography variant='body1'>{customer.name}</Typography></Fragment>} placement='top' arrow><TableCell> {_.truncate(customer.name, { 'length': 20 })}</TableCell></Tooltip>
                                            <Tooltip title={<Fragment><Typography variant='body1'>{customer.address}</Typography></Fragment>} placement='top' arrow><TableCell> {_.truncate(customer.address, { 'length': 15 })}</TableCell></Tooltip>
                                            <TableCell>{customer.whatsapp.substring(0, 3) + '-' + customer.whatsapp.substring(3, 6) + '-' + customer.whatsapp.substring(6, 10)}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>)
                        : <Typography
                            variant='h4'
                            align='center'
                        >   Inicia la búsqueda</Typography>}
            </Box>
            {/* SELECT CUSTOMER */}
            <Box p={1} className={classes.selectButton}>
                <Button
                    variant='contained'
                    color='primary'
                    onClick={exportCustomerSelectedInfo}
                    {...((selectedCustomer.name) ? { disabled: false } : { disabled: true })}
                > Seleccionar </Button>
            </Box>
        </Dialog>
    )
}

export default FindCustomer
