import React, { useState, useEffect } from 'react'
import { List, ListItem, ListItemText, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
    itemDetails: {
        '& .MuiListItem-root': {
            paddingBottom: theme.spacing(0),
            paddingTop: theme.spacing(0),
            paddingRight: theme.spacing(0),
            cursor: 'default',
        },
    },
    listItemWrapper: {
        borderBottom: '1px dashed #7986cb',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1),
    },
}))
const Receipt = (props) => {
    const classes = useStyles();
    const { items, expanded } = props;
    const [open, setOpen] = useState([]);
    const [arrayUpdated, setArrayUpdated] = useState(false);

    //Initial setup, false filled array
    useEffect(() => {
        const fillOpenObject = () => {
            _.map(items, (item, idx) => {
                const arr = open;
                arr.push(false)
                setOpen(arr);
            })
        };
        fillOpenObject();
    }, [items, open])

    //Update open array by Item pressed.
    const handleClick = (index) => {
        const arr = open;
        arr[index] = !arr[index];
        setOpen(arr);
        setArrayUpdated(true)
    };
    //Re render receipt component after Item title pressed. 
    useEffect(() => {
        const changeOpenArr = () => {
            setOpen(open);
            setArrayUpdated(false)
        };
        changeOpenArr();
    }, [open, arrayUpdated])

    //Expand or contract every item
    useEffect(() => {
        const checkExpanded = () => {
            const arr = [];
            if (expanded) {
                _.map(items, (item, idx) => {

                    arr.push(true)
                    setOpen(arr);
                })
            } else {
                _.map(items, (item, idx) => {
                    const arr = [];
                    arr.push(false)
                    setOpen(arr);
                })
            }
        };
        checkExpanded();
    }, [expanded, items])

    return (
        <List component="ul">
            {_.map(items, (item, idx) => {
                return (
                        <div key={idx} className={classes.listItemWrapper} >
                        <ListItem key={idx} disableGutters onClick={() => handleClick(idx)}>
                            <ListItemText className='itemTitle'>{item.name}</ListItemText>
                            <span className='itemTitleIndex'># {idx + 1}</span>
                        </ListItem>
                        {open !== false ?
                            <Collapse in={open[idx]} timeout="auto" unmountOnExit className={classes.itemDetails}>
                                <List component="div" disablePadding>
                                    <ListItem>
                                        <ListItemText> Un/Kg</ListItemText>
                                    $ {_.round(item.price).toLocaleString('de-DE')}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Cantidad</ListItemText>
                                        {item.qty}
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText> Precio</ListItemText>
                                    $ {_.round(item.qty * item.price).toLocaleString('de-DE')}
                                    </ListItem>
                                </List>
                            </Collapse>
                            : null}
                    </div>
                )
            })}
        </List>
    )
}

export default Receipt
