import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { Dialog, Grid, Box, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles'
import _ from 'lodash';
import Toolbar from './Toolbar';

import ReceiptInfo from './checkOutWindow/Receipt';
import CustomerInfo from './checkOutWindow/CustomerInfo'
import DiscountsInfo from './checkOutWindow/Discounts'
import DeliveryInfo from './checkOutWindow/OrderDelivery'
import PaymentInfo from './checkOutWindow/PaymentMethod'

const useStyles = makeStyles(theme => ({
    dialogContainer: {
        '& > .MuiDialog-container > .MuiDialog-paper': {
            margin: "0px",
            maxHeight: '100%'
        }
    },
    root: {
        height: "100vh",
    },
    gridContainer: {
        width: "100%",
        display: "flex",
        minHeight: "calc(100% - 64px - 64px)",
        [theme.breakpoints.down('sm')]: {
            minHeight: "calc(100% - 56px - 64px)"
        },
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "stretch",
    },
    leftMainContainer: {
        backgroundColor: "rgb(255, 255, 255)",
        overflow: 'auto',
        maxHeight: '80vh',
        borderRight: '1px solid rgba(230, 230, 230, 0.4)',
        // For shopping cart products names. 
        '& .itemTitle .MuiTypography-root': {
            fontWeight: 'bold',
            fontStyle: 'italic'
        },
        '& .itemTitle:hover ': {
            cursor: 'pointer',
        },
        '& .itemTitleIndex': {
            color: theme.palette.primary.dark,
        },
    },
    receiptFixedContainer: {
        position: 'sticky',
        top: theme.spacing(0),
        paddingTop: theme.spacing(1),
        zIndex: 1,
        backgroundColor: "#fff"
    },
    receiptContainer: {
        position: 'relative',
        margin: theme.spacing(1),
        marginBottom: theme.spacing(0),
    },
    receiptTitle: {

    },
    expandButton: {
        position: 'absolute',
        right: '0px',
        top: theme.spacing(0),
    },
    rightMainContainer: {
        backgroundColor: "rgba(230, 230, 230, 0.5)",
        '& .cardContainer:nth-child(odd)': {
            paddingLeft: theme.spacing(2),
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(1),
            minHeight: "200px",
            maxHeight: "300px",
        },
        '& .cardContainer:nth-child(even)': {
            paddingTop: theme.spacing(2),
            paddingRight: theme.spacing(2),
            paddingLeft: theme.spacing(1),
            minHeight: "200px",
            maxHeight: "300px",
        },
        '& .cardContainer:nth-child(3), & .cardContainer:nth-child(4)': {
            paddingBottom: theme.spacing(2),
            minHeight: "300px",
            maxHeight: "300px",
        },
        // '& .cardContainer:last-child': {
        //     paddingBottom: theme.spacing(2),
        //     paddingRight: theme.spacing(2),
        //     minHeight: "185px",
        //     maxHeight: "200px",
        // },
    },
    rightContainer: {
        backgroundColor: "rgba(230, 230, 230, 0.5)",
    },
    content: {
        minHeight: '100%',
        backgroundColor: "#FF00FF",
    },
    footer: {
        height: '64px',
    },
    footerGridContainer: {
        height: '100%',
    },
    footerLeftContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    footerRightContainer: {
        display: 'flex',
    },
    footerDividerBox: {
        minWidth: '50%',
        display: 'flex',
        alignItems: 'center',
    },
    amountBox: {
        // paddingLeft: '50%',
        paddingLeft: '16px',
        cursor: 'default'
    },
    paymentBox: {
        width: "50%",
        cursor: 'default',
        paddingLeft: theme.spacing(2)
    },
    changeBox: {
        width: "50%",
        cursor: 'default'
    },
    buttonContainer: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttons: {
        margin: '0 8px',
    },
}));

const CheckOutWindow = (props) => {
    const { subTotal, items } = props
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const initCustomerInfo = {
        _id: '',
        name: '',
        id: '',
        address: '',
        telephone: '',
        whatsapp: '',
        email: '',
        creditAvailable: '',
        creditAmount: '',
        creditDeadlines: '',
        creditBalance: '',
        status: true,
    };
    const initPaymentInfo = {
        "cash": 0,
        "credit-card": 0,
        "debit-card": 0,
        "credit-customer": 0
    };
    const initCheckOutInfo = {
        "total": 0,
        "payment": 0,
        "change": 0
    }
    const [departments, setDepartments] = useState([]);
    const [customerInfo, setCustomerInfo] = useState(initCustomerInfo);
    const [deliveryInfo, setDeliveryInfo] = useState(null);
    const [paymentInfo, setPaymentInfo] = useState(initPaymentInfo);
    const [checkOutInfo, setCheckOutInfo] = useState(initCheckOutInfo);

    // EXPAND OR COLLAPSE LIST ITEMS
    const handleExpand = () => {
        setExpanded(!expanded);
    };

    // LIST DEPARTMENTS ITEMS
    useEffect(() => {
        const listDepartments = () => {
            let arr = [];
            _.map(items, (item) => {
                arr.push(item.department)
            });
            arr = _.uniq(arr)
            setDepartments(arr);
        }
        listDepartments();
    }, [])
    
    /* --------------------------------- CUSTOMER SECTION ------------------------------ */
    // RECEIVE CUSTOMER DATA FROM CUSTOMERINFO COMPONENT
    const customerInfoUpdate = (data) => {
        setCustomerInfo(data);
    }
    
    /* --------------------------------- DISCOUNT SECTION ------------------------------ */
    
    
    /* --------------------------------- DELIVERY SECTION ------------------------------ */
    const deliveryInfoUpdate = (data) => {
        setDeliveryInfo(data);
        // console.log(data);
    }

    /* --------------------------------- PAYMENT SECTION ------------------------------- */
    // GET TOTAL FROM PARENT COMPONENT
    useEffect(() => {
        const setSubTotal = () => {
            setCheckOutInfo({ ...checkOutInfo, 'total': subTotal })
        };
        setSubTotal();
    }, [subTotal])

    // RECEIVE PAYMENT DATA FROM PAYMENTINFO COMPONENT
    const paymentInfoUpdate = (data) => {
        setPaymentInfo(data);
        setCheckOutInfo({ ...checkOutInfo, 'payment': data["cash"] + data["credit-card"] + data["debit-card"] + data["credit-customer"] })
    }

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            disableEscapeKeyDown
            open={props.open}
            onClose={props.close}
            className={classes.dialogContainer}
        >
            <Box className={classes.root}>
                {/* TOOLBAR */}
                <Toolbar title={'CheckOutWindow'} close={props.close} />
                {/* CONTAINER */}
                <Grid container className={classes.gridContainer}>
                    {/* LEFT CONTAINER */}
                    <Grid item xs={12} sm={4} className={clsx(classes.leftMainContainer, "hideScrollbar")}>
                        <div className={classes.receiptFixedContainer}>
                            <Box className={classes.receiptContainer}>
                                <Typography variant='h5' className={classes.receiptTitle}>
                                    Factura
                            </Typography>
                                <Button
                                    className={classes.expandButton}
                                    onClick={handleExpand}
                                    color='primary'
                                    size='small'
                                    {...((expanded) ? { variant: 'outlined' } : { variant: 'contained' })}
                                >
                                    {expanded ? 'Contraer Todo' : 'Expandir Todo'}
                                </Button>
                            </Box>
                        </div>
                        <ReceiptInfo items={items} expanded={expanded} />
                    </Grid>
                    {/* RIGHT CONTAINER */}
                    <Grid item container xs={12} sm className={classes.rightMainContainer}>
                        <Grid item xs={12} sm={6} className={clsx(classes.rightContainer, "cardContainer")}>
                            <CustomerInfo customerInfoUpdate={customerInfoUpdate}/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={clsx(classes.rightContainer, "cardContainer")}>
                            <DiscountsInfo departments={departments} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={clsx(classes.rightContainer, "cardContainer")}>
                            <DeliveryInfo customerInfo={customerInfo} deliveryInfoUpdate={deliveryInfoUpdate}/>
                        </Grid>
                        <Grid item xs={12} sm={6} className={clsx(classes.rightContainer, "cardContainer")}>
                            <PaymentInfo paymentInfoUpdate={paymentInfoUpdate} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* FOOTER */}
                <Box className={classes.footer}>
                    <Grid container className={classes.footerGridContainer}>
                        <Grid item xs={12} sm={4} className={classes.footerLeftContainer}>
                            <Box className={clsx(classes.amountBox)}>
                                <Typography variant='body1' color='primary'>Total:</Typography>
                                <Typography variant='h5' color='primary'>$ {(checkOutInfo['total']).toLocaleString('de-DE')}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} className={classes.footerRightContainer}>
                            <Box className={classes.footerDividerBox}>
                                <Box className={classes.paymentBox}>
                                    <Typography variant='body1'>Cobrado:</Typography>
                                    <Typography variant='h5'>$ {(checkOutInfo['payment']).toLocaleString('de-DE')}</Typography>
                                </Box>
                                <Box className={classes.changeBox}>
                                    <Typography
                                        variant='body1'
                                        {...((checkOutInfo['payment'] - checkOutInfo['total']) < 0 ? { color: 'error' } : { color: 'textPrimary' })}
                                    >Cambio:</Typography>
                                    <Typography
                                        variant='h5'
                                        {...((checkOutInfo['payment'] - checkOutInfo['total']) < 0 ? { color: 'error' } : { color: 'textPrimary' })}
                                    >$ {(checkOutInfo['payment'] - checkOutInfo['total']).toLocaleString('de-DE')}</Typography>
                                </Box>
                            </Box>
                            <Box className={clsx(classes.footerDividerBox, classes.buttonContainer)}>
                                <Button
                                    variant='contained'
                                    className={classes.buttons}
                                    color='secondary'
                                > Devolver </Button>
                                <Button
                                    className={classes.buttons}
                                    variant='contained'
                                    color='primary'
                                    {...((((checkOutInfo['payment'] - checkOutInfo['total']) < 0) || (paymentInfo['credit-card'] + paymentInfo['debit-card'] + paymentInfo['credit-customer'] > checkOutInfo['total'])) ? { disabled: true } : { disabled: false })}
                                > Facturar </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Dialog>
    )
}

export default CheckOutWindow
