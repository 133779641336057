import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import departmentsReport from 'utils/salesByDepts';

const MovementsReport = (props) => {
  const { backendURL, updateMovements } = props;
  const [deptsSales, setDeptsSales] = useState({});
  const [depts, setDepts] = useState([]);

  useEffect(() => {
    const effectFunction = async () => {
      if (updateMovements) {
        var departments = await Axios.get(`${backendURL}/features/methods`)
        if(departments.data.length !== 0){
          console.log(departments);
          departments = departments.data[0].departments
          setDepts(departments);
          //Sales by departments
          const salesByDepts = await departmentsReport(backendURL);
          console.log(salesByDepts);
          setDeptsSales(salesByDepts);
        }
      }
    };
    effectFunction();
  }, [backendURL, updateMovements])

  return (
    <div>
      {depts.map((storeDepartment, i) => (
        <h2 key={i}>
          {deptsSales[i] !== 0 ? (
            <div>
              {storeDepartment}: {deptsSales[i]}
            </div>) : null}
          
        </h2>
      ))}
    </div>
  )
}

export default MovementsReport;