import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import '../../../CSS/admin.dashboard.css';
import {
  Typography, Box, Drawer, Container, 
  IconButton, AppBar, Divider, Toolbar, 
  CssBaseline, List, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core"

//----------------------------- ICONS ----------------------------------------------
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PeopleIcon from '@material-ui/icons/People';
import BarChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Settings';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import { makeStyles } from '@material-ui/core/styles';
/** ----------------------------------------------------------------------------- */
import Home from './home/Home';
import Sales from './sales/Sales';
import ReportsTable from './reports/ReportsList'
import CustomersTable from './customers/CustomersList'
import WorkersTable from './workers/WorkersList';
import ProductsTable from './products/ProductsList'
import PointOfSale from './pointOfSale/PointOfSale'
import Settings from './settingsTab/SettingsTab'
import Logout from './logout/Logout'

/**-----------------------------logged in check module----------------------- */
import isLoggedIn from 'components/Login/isLoggedIn'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const drawerWidth = 230;
const mobileBreakpoint = 960; //drawer variant breakpoint

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    height: '100vh',
    '& #vertical-tabpanel-5 .MuiBox-root': {
      padding: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    }
  },
  root2: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: "64px",
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  container: {
    padding: "0px",
    overflow: 'auto'
  },
  contentOpen: {
    width: "calc(100vw - 240px)",
    overflow: 'auto',
    [theme.breakpoints.down(mobileBreakpoint)]: {
      width: "100%"
    }
  },
  contentClose: {
    // width: "calc(100vw - 64px)"
    width: '100%',
    overflow: 'auto',
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.down(500)]: {
      width: "100%"
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(6),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8),
    },
  },
  menuButton: {
    marginRight: 36,
    marginLeft: 0
  },
  menuButtonHidden: {
    display: 'none',
  },
  tabs: {
    // borderRight: `1px solid ${theme.palette.divider}`,
    backgroundColor: "white",
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
  },
  value9: {
    display: "flex",
    alignItems: "center"
  },
}));

const tabsStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    '& a': {
      textDecoration: "none",
      color: "inherit"
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableHead: {
    '& tr th': {
      position: 'relative'
    },
    '& tr th span svg': {
      position: 'absolute',
      zIndex: '10',
      top: 0,
      right: -25,
    }
  },
}));

const itemModalStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    margin: "16px auto",
    marginBottom: theme.spacing(3),
    '& .makeStyles-button-348:last-child': {
      marginRight: "0px",
    }
  },
  button: {
    display: 'inline-box',
    marginRight: theme.spacing(2)
  },
  hideStats: {
    display: "none"
  },
  root: {
    height: "100%"
  },
  showStats: {
    height: "200px"
  },
  // Next ones are for Updating Modal Form
  divWarning: {
    // display: "inline-block",
    // width: "calc(33.33% - 16px)",
    // marginRight: theme.spacing(2),
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      color: theme.palette.error.dark
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline ': {
      borderWidth: "3px",
      borderColor: theme.palette.error.dark
    },

  },
  fullWidth: {
    display: "inline-block",
    width: "calc(100% - 16px)",
    marginRight: theme.spacing(2)
  },
  fullScreen: {
    width: "30vh",
    height: "30vh"
  },
  noShow: {
    display: "none",
  },
  show: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: 0,
    color: theme.palette.error.dark,
    fontSize: theme.spacing(2),
    textAlign: "center",
    fontStyle: "bold"
  },
  hideBackground: {
    backgroundColor: "transparent !important",
    overflow: "hidden",
    '& .MuiDialog-paperScrollPaper': {
      background: "none"
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
  marginBottom: {
    marginBottom: theme.spacing(2)
  }
}))

const enhTableToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    // minWidth: "200px"
    minHeight: '42px',
    maxHeight: '42px',
  },
  iconButton: {
    "& .MuiButton-label .MuiButton-startIcon": {
      margin: 0,

    },
    "& .MuiButton-label .buttonTag": {
      display: "none",
    }
  },
  maxHeight: {
    "& .MuiInputBase-root": {
      maxHeight: '44px',
    }
  },
  title: {
    flex: '1 1 100%',
    textAlign: "left",
  },
}));

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      // console.log(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export default function VerticalTabs(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(7);//Starting Tab
  const [open, setOpen] = React.useState(false);//Menu drawer opened
  const width = useWindowSize(); //Used to change drawer variant

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
    // console.log(open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setSalesTab = useCallback(() => {
    setValue(2);
  }, [])

  useEffect(() => {
    isLoggedIn('User Info');
  }, [])

  //---------------------- Close Temporary Drawer -----------------------
  useEffect(() => {
    const closeDrawer = () => {
      if (width < mobileBreakpoint) {
        handleDrawerClose();
      }
    }
    closeDrawer();
  }, [value, width])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            {value === 1 && (<span>Dashboard</span>)}
            {value === 2 && (<span>Ventas</span>)}
            {value === 3 && (<span>Inventario</span>)}
            {value === 4 && (<span>Clientes</span>)}
            {value === 5 && (<span>Trabajadores</span>)}
            {value === 6 && (<span>Reportes</span>)}
            {value === 7 && (<span>Punto de Venta</span>)}
            {value === 8 && (<span>Configuración</span>)}
            {value === 9 && (<span>Cerrar Sesión</span>)}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        {... (width < mobileBreakpoint ? { variant: "temporary" } : { variant: "permanent" })}
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon} onClick={handleDrawerClose}>
          <IconButton>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <div className={classes.root2}>
            <Divider />
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              selected={value === 1}
              onClick={(event) => handleChange(event, 1)}
            >
              <ListItemIcon>
                  <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Inicio" />
            </ListItem>
              <ListItem
                button
                selected={value === 2}
                onClick={(event) => handleChange(event, 2)}
              >
                <ListItemIcon>
                  <AttachMoneyIcon />
                </ListItemIcon>
                <ListItemText primary="Ventas" />
              </ListItem>
              <ListItem
              button
              selected={value === 3}
              onClick={(event) => handleChange(event, 3)}
            >
              <ListItemIcon>
                  <ShoppingCartIcon />
              </ListItemIcon>
              <ListItemText primary="Productos" />
            </ListItem>
            <ListItem
              button
              selected={value === 4}
              onClick={(event) => handleChange(event, 4)}
            >
              <ListItemIcon>
                  <PeopleIcon />
              </ListItemIcon>
              <ListItemText primary="Clientes" />
            </ListItem>
            <ListItem
              button
              selected={value === 5}
              onClick={(event) => handleChange(event, 5)}
            >
              <ListItemIcon>
                  <AssignmentIndIcon />
              </ListItemIcon>
              <ListItemText primary="Personal" />
            </ListItem>
            <ListItem
              button
              selected={value === 6}
              onClick={(event) => handleChange(event, 6)}
            >
              <ListItemIcon>
                  <BarChartIcon />
              </ListItemIcon>
              <ListItemText primary="Reportes" />
            </ListItem>
            <ListItem
              button
              selected={value === 7}
              onClick={(event) => handleChange(event, 7)}
            >
              <ListItemIcon>
                  <ReceiptIcon />
              </ListItemIcon>
              <ListItemText primary="Punto de Venta" />
            </ListItem>
            <ListItem
              button
              selected={value === 8}
              onClick={(event) => handleChange(event, 8)}
            >
              <ListItemIcon>
                  <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Configuración" />
            </ListItem>
            <ListItem
              button
              selected={value === 9}
              onClick={(event) => handleChange(event, 9)}
            >
              <ListItemIcon>
                  <PowerSettingsNewIcon />
              </ListItemIcon>
              <ListItemText primary="Cerrar Sesión" />
            </ListItem>
          </List>
        </div>
      </Drawer>
      <main className={clsx(open ? classes.contentOpen : classes.contentClose, value === 9 ? classes.value9 : classes.none)}>
        <div className={classes.toolbar} />
        <Container maxWidth="xl" className={classes.container}>
          <TabPanel value={value} index={1}>
            <Home setSalesTab={setSalesTab} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Sales 
              classes={tabsStyles()}
              itemModalClasses={itemModalStyles()}
              enhTableToolbarClasses={enhTableToolbarStyles()} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <ProductsTable
              classes={tabsStyles()}
              itemModalClasses={itemModalStyles()}
              enhTableToolbarClasses={enhTableToolbarStyles()} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <CustomersTable
              classes={tabsStyles()}
              itemModalClasses={itemModalStyles()}
              enhTableToolbarClasses={enhTableToolbarStyles()} />
          </TabPanel>
          <TabPanel value={value} index={5}>
            <WorkersTable
              classes={tabsStyles()}
              itemModalClasses={itemModalStyles()}
              enhTableToolbarClasses={enhTableToolbarStyles()} />
          </TabPanel>
          <TabPanel value={value} index={6}>
            <ReportsTable />
          </TabPanel>
          <TabPanel value={value} index={7}>
            <PointOfSale username={props.username}/>
          </TabPanel>
          <TabPanel value={value} index={8}>
            <Settings />
          </TabPanel>
          <TabPanel value={value} index={9}>
            {value === 9 && (<Logout />)}
          </TabPanel>
        </Container>
      </main>
    </div>
  );
}