import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import formatNames from 'utils/setPaymentMethodsNames'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.background.default,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme)=>({
  root: {
    width: '100%',
    // marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: '100%',
  },
}));

export default function CashierReport (props) {
  const classes = useStyles();
  const {backendURL, updateCashier} = props;

  const [cashier, setCashier] = useState([])
  
  useEffect(()=>{
    const effectFunction = async () => {
      // console.log(updateCashier);
      if(updateCashier){
        var cashierInfo = await Axios.get(`${backendURL}/features/methods`)
        cashierInfo = cashierInfo.data[0];
        // console.log(cashierInfo.cashierCurrent);
        // console.log(cashierInfo.cashierStartDay);
        // console.log(cashierInfo.paymentMethods);

        // setCurrentCashier(cashierInfo.data[0].cashierCurrent);
        // setStartCashier(cashierInfo.data[0].cashierStartDay)
        var cashierArrObj = []
        var i= 0;
        if(cashierInfo !== undefined){
          for(i=0; i < cashierInfo.paymentMethods.length; i++){
            cashierArrObj.push({
              method: cashierInfo.paymentMethods[i],
              start: cashierInfo.cashierStartDay[cashierInfo.paymentMethods[i]],
              current: cashierInfo.cashierCurrent[cashierInfo.paymentMethods[i]]
            })
          }
          console.log(cashierArrObj);
          setCashier(cashierArrObj);
        }
      }
    };
    effectFunction();
  },[updateCashier, backendURL])

  return(
    <div className={classes.root}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center"> Medio de Pago </StyledTableCell>
            <StyledTableCell align="center">Cantidad Inicial</StyledTableCell>
            <StyledTableCell align="center">Cantidad Actual</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cashier.map(row => (
            <StyledTableRow key={row.method}>
              <StyledTableCell component="th" scope="row" align="center">
                {formatNames(row.method)}
              </StyledTableCell >
              <StyledTableCell align="center">{row.start}</StyledTableCell>
              <StyledTableCell align="center">{row.current}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
};