import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Tabs, Tab, Box, Typography, TextField,
  InputAdornment, Checkbox, FormControl,
  FormControlLabel, FormGroup
} from '@material-ui/core';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FaceIcon from '@material-ui/icons/Face';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    height: "100%",
    '& .MuiTabs-flexContainer .MuiTab-root:first-child .MuiTab-wrapper': {
      flexDirection: 'row',
      '& .MuiSvgIcon-root': {
        width: '1.5em',
        height: '1.5em',
      }
    },
    '& .MuiTabs-flexContainer .MuiTab-root .MuiTab-wrapper': {
      flexDirection: 'row',
    },
    '& .MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root': {
      minWidth: '0px'
    },
    '& .MuiTabs-scroller .MuiButtonBase-root .MuiTab-wrapper svg': {
      marginLeft: '0px',
      marginRight: '4px',
      textAlign: 'left',
    },
  },
  main: {
    height: "calc(100% - 54px)",
    display: 'flex',
    justifyContent: 'center',
    '& .MuiTypography-root': {
      display: "flex",
      alignItems: 'center',
      justifyContent: 'center',
      '& .MuiBox-root': {
        padding: "0px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    '& .textArea': {
      '& .MuiInputBase-root .MuiInputBase-input': {
        fontSize: '1.15rem',
        textAlign: 'left',
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '25ch',
    '& .MuiInputBase-root .MuiInputAdornment-root .MuiTypography-body1 ': {
      fontSize: '1.5rem',
    },
    '& .MuiInputBase-root .MuiInputBase-input': {
      textAlign: 'right',
      fontSize: '2.5rem',
    },
  },
}));

export default function FullWidthTabs(props) {
  const { departments } = props;
  const classes = useStyles();
  const [value, setValue] = useState(1);
  const [discountData, setDiscountData] = useState({
    type: 'price',
    amount: 0,
    reason: '',
  })

  // HANDLE TAB CHANGE
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // HANDLE DISCOUNT DATA UPDATES
  const handleDiscountType = (field) => event => {
    setDiscountData({
      ...discountData,
      ['type']: field,
      ['amount']: event.target.value
    })
  }
  const handleDiscountReason = event => {
    setDiscountData({
      ...discountData,
      ['reason']: event.target.value
    })
  }

  // RESET WHEN DISCOUNT TYPE CHANGE
  useEffect(() => {
    const resetDiscountData = () => {
      console.log('Change Discount Type')
    };
    resetDiscountData()
  }, [discountData['type']])

  return (
    <div className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="Cantidad" icon={<AttachMoneyIcon />} />
        <Tab label="Depts" icon={<LocalGroceryStoreIcon />} />
        <Tab label="Miembros" icon={<FaceIcon />} />

      </Tabs>
      <main className={classes.main}>
        <TabPanel value={value} index={0}>
          <TextField
            className={clsx(classes.margin, classes.textField)}
            onChange={handleDiscountType('amount')}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
          />
          <TextField
            rows={2}
            placeholder='Motivo'
            onChange={handleDiscountReason}
            className={clsx(classes.margin, classes.textField, 'textArea')}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box>
            <FormControl component="fieldset">
              {_.map(departments, (department, idx) =>{
                return(
                  <FormControlLabel
                    label={department}
                    control={<Checkbox color="primary" />}
                  />
                )
              })} 
            </FormControl>
            {/* <TextField
              className={clsx(classes.margin, classes.textField)}
              onChange={handleDiscountType('department')}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
              }}
            /> */}
          </Box>
          <TextField
            rows={2}
            placeholder='Motivo'
            onChange={handleDiscountReason}
            className={clsx(classes.margin, classes.textField, 'textArea')}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          Item Three
        </TabPanel>
      </main>
    </div>
  );
}