import Axios from 'axios';

function createData(_id, id, name, status, username, address, whatsapp, cellphone, role) {
  return { _id, id, name, status, username, address, whatsapp, cellphone, role };
}

const data = async (searchParams) => {
  let rows = [];
  let usersArr = [];
  var params = searchParams || { inputField: "", selectField: "name" }

  if (params.selectField === "name" && params.inputField.length >= 2) {
    // console.log(params)
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/regex?input=${params.inputField}&option=${params.selectField}`)
    // console.log(res.data);
    usersArr = res.data;
  } else if ((params.selectField === "username" || params.selectField === "id" || params.selectField === "cellphone" 
    || params.selectField === "whatsapp" || params.selectField === "role") && params.inputField.length >= 1) {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/users/regex?input=${params.inputField}&option=${params.selectField}`)
    usersArr = res.data;
  } else {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/users`);
    usersArr = res.data;
    // console.log(usersArr);
  }

  usersArr.map(user => {
    return rows.push(
      createData(user._id, user.id, user.name, user.status ? 'Activo' : 'Inactivo', user.username, user.address,
        user.whatsapp, user.cellphone, user.role)
    );

  })
  return rows;
};

export default data;