import Axios from 'axios';

const departments = async () => {
  let depts = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/features/methods`)
  // console.log(depts.data[0].departments);
  if(depts.data.length){
    depts = depts.data[0].departments
  }
  else{
    depts = depts.data
  }
  // console.log(depts);
  if (depts.length) {
    
  } else {
    depts = ['res', 'lacteos', 'aves', 'embutidos'];
  }
  const deptObject = [];
  depts.map(item => {
    return deptObject.push({ value: item, label: item })
  })
  return deptObject;
}

export default departments;