import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    height: "77vh",
  },
  active: {
    backgroundColor: '#b2ff59',
  },
  some: {
    backgroundColor: '#fff59d',
  },
  canceled: {
    backgroundColor: '#ef9a9a',
  },
  text: {
    color: 'black'
  },
  divider: {
    backgroundColor: '#000000'
  }
}));

function formatNumber(number) {
  var ln = number.length
  number = number.split('')
  number.splice(ln - 3, 0, '.')
  number = number.join('')
  return (number)
}

export default function SimpleList(props) {
  const classes = useStyles();
  const { backendURL, updateList } = props;

  const [receiptsList, setList] = useState([]);

  useEffect(() => {
    const effectFunction = async () => {
      if (updateList) {
        var resultList = await Axios.get(`${backendURL}/closedReceipts/productionDate`)
        console.log(resultList.data);
        if(resultList.data.length > 1){
          setList(resultList.data.reverse())
        }
      }
    };
    effectFunction()
  }, [updateList, backendURL])

  const handleClick = (number) => {
    console.log(`Receipt No: ${number}`);
  }

  return (
    <div className={classes.root}>
      <List component="nav">
        {receiptsList.length > 0
          ? (receiptsList.map(receipt => (
            <div key={receipt._id}>
              <ListItem
                button
                onClick={() => handleClick(receipt.receipt)}
                className={receipt.status === 'active' ? (classes.active) : (receipt.status === 'some' ? classes.some : classes.canceled)}>
                {receipt.status === 'active' && (<ListItemIcon><CheckCircleIcon /></ListItemIcon>)}
                {receipt.status === 'some' && (<ListItemIcon><ErrorIcon /></ListItemIcon>)}
                {receipt.status === 'canceled' && (<ListItemIcon><CancelIcon /></ListItemIcon>)}

                <ListItemText
                  className={classes.text}
                  primary={`Factura No: ${receipt.receipt}`}
                  secondary={
                    // <p className={classes.text}>
                    `Total: $${formatNumber(receipt.total.toString())}`
                    // </p>
                  }
                />
              </ListItem>
              <Divider className={classes.divider} />
            </div>
          )))
          : null
        }
      </List>

    </div>
  );
}