import React, { memo, useEffect, useState, useLayoutEffect } from 'react';
import clsx from 'clsx';
import { Toolbar, Typography, Tooltip, Button, Grid, MenuItem, TextField, InputAdornment } from '@material-ui/core'
import DeliveryIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
      // console.log(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const EnhancedTableToolbar = memo(props => {
  const { classes } = props;
  const { fieldsChange } = props;
  const { openAddModal } = props;
  // const { employees } = props;
  const [value, setValue] = useState({
    inputField: '',
    selectField: 'name'
  });
  const width = useWindowSize()

  const handleChange = name => event => {
    setValue({
      ...value,
      [name]: event.target.value
    })
  }
  useEffect(() => {
    fieldsChange(value);
    // console.log(value);
  }, [value, fieldsChange])

  return (
    <Toolbar
      className={clsx(classes.root)}
    >
      <Grid container alignItems='center' spacing={2} justify='center'>
        <Grid item xs={12} sm={4} md={4} lg={5}>
      <Typography className={classes.title} variant="h5" id="tableTitle" align="left">
        Tus Pedidos ({15})
      </Typography>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3}>
          <TextField
            id="toolbarSearchfield"
            className={clsx(classes.maxHeight)}
            variant="outlined"
            value={value.inputField}
            onChange={handleChange('inputField')}
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          // {...(value.selectField === 'name' ? {type:'string'} : {type:'number'})}
          />
        </Grid>
        <Grid item xs={9} sm={3} md={2} lg={2}>
          <TextField
            id="toolbarOption"
            value={value.selectField}
            className={clsx(classes.maxHeight)}
            onChange={handleChange('selectField')}
            select
            variant='outlined'
            fullWidth
          >
            <MenuItem value="name">Nombre</MenuItem>
            <MenuItem value="username">Usuario</MenuItem>
            <MenuItem value="id">Identificacion</MenuItem>
            <MenuItem value="cellphone">Telefono</MenuItem>
            <MenuItem value="whatsapp">Whatsapp</MenuItem>
            <MenuItem value="role">Rol</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={3} sm={2} md={3} lg={2}>
          {width > 0 ?
      <Tooltip title="Agregar Pedido">
        <Button
        fullWidth
        id="toolbarNew"
          variant="contained"
          color="secondary"
          className={clsx(width < 760 ? classes.iconButton : null, classes.button)}
          startIcon={<DeliveryIcon />}
          onClick={openAddModal}
        ><span className="buttonTag">Agregar</span> </Button>
      </Tooltip>
      : null}
      </Grid>
      </Grid>
    </Toolbar>
  );
})

export default EnhancedTableToolbar;