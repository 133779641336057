import React, {useState, memo} from 'react';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

const SwitchLabels = memo((props) => {
  const [isWheighted, setIsWheighted] = useState(false);
  const changeProductType = props.change;

  const handleChange = event => {
    setIsWheighted(event.target.checked);
    // console.log(event.target.checked)
    changeProductType()
  };

  return (
    <div>
    <FormGroup row>
      <FormControlLabel
        control={
          <Switch
            checked={isWheighted}
            onChange={handleChange}
            color="primary"
          />
        }
        label={isWheighted ? "Pesado" : "Unitario"}
      />
      </FormGroup>
    </div>
  );
})

export default SwitchLabels