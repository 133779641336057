import React from 'react';
import clsx from 'clsx'
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  close:{
    position:'absolute',
    right: '10px',
    cursor: 'pointer'
  },
  title: {
    flexGrow: 1,
  },
}));

const ToolBar = (props) => {
  const classes = useStyles();
  const handleClose = () => {
    props.close();
  }
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant='h5' align='center' className={classes.title}>
          {props.title}
          </Typography>
        <Typography variant='h5' align='center' className={clsx(classes.title, classes.close)} onClick={handleClose}>
          X
          </Typography>
      </Toolbar>
    </AppBar>
  )
};

export default ToolBar;