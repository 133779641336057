import React from "react";
import { Redirect } from "react-router-dom"

function RedirectToLoginPage(props) {
    const isAuth = props.isAuth;

    return (
        isAuth === false ? (< Redirect to="/" />) : (null)
    )
}

export default RedirectToLoginPage;