import React, { Component } from "react";
import axios from "axios";

import "./operator.css"

import Auth from "../components/RedirectToLoginPage";
import Navbar from "../components/operator/OperatorNavigation";


class Operator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // isAuth: this.props.location.state.isAuthenticated,
      isAuth: true,
      username: '',
      backendUrl: '',
      user: []
    }
  }
  async componentDidMount() {
    if (this.props.location.state === undefined) {
      this.setState({
        isAuth: false
      })
      console.log(typeof (this.props.location.state));
    } else {
      this.setState({
        username: this.props.location.state.username,
        backendUrl: this.props.location.state.backendURL,
      })
      const res = await axios.get(this.props.location.state.backendURL + '/users?username=' + this.props.location.state.username);
      if (res.data.length > 0) {
        this.setState({
          user: res.data[0]
        })
      }
    }

    // console.log(this.state);
  }

  render() {
    return (
      <div>
      {(this.props.location.state !== undefined) ? (
        <div>
        <Navbar backendURL={this.props.location.state.backendURL} username={this.props.location.state.username} />
        <Auth isAuth={this.state.isAuth} /> {/* Agregar isAuthenticated para mantener o redireccionar a login */}
      </div>
      ) : (
        <Auth isAuth={false}/>
      )}
      </div>
    )
  }
}

export default Operator;