import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {LinearProgress, Typography} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
    marginBottom: theme.spacing(3),
  },
}));

export default function LinearDeterminate(props) {
  const classes = useStyles();
  const [completed, setCompleted] = React.useState(0);
  const [currentSales, setCurrentSales] = React.useState(0);
  const { goal } = props;

  React.useEffect(() => {
    function progress() {
      setCompleted(oldCompleted => {
        // if (oldCompleted === 100) {
        //   return 0;
        // }
        const diff = 100000/goal *100
        return Math.min((oldCompleted + diff),100);
      });
      setCurrentSales(sales => {
        const diff = 100000;
        return (sales + diff)
      });
    }

    const timer = setInterval(progress, 500);
    return () => {
      clearInterval(timer);
    };
  }, [goal]);

  return (
    <div className={classes.root}>
      <LinearProgress variant="determinate" value={completed} color={"primary"} className={classes.linear}/>
      <Typography>
        {`$${currentSales.toLocaleString()} (${Math.floor(currentSales/goal*100)} %)` }
      </Typography>
    </div>
  );
}