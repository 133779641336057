import React, {useState, useEffect, useContext} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import SearchAppBar from './ReportesParciales/SearchAppBar';
import ReceiptsList from './ReportesParciales/ReceiptsList';
import CashierReport from './ReportesParciales/CashierReport';
import MovementsReport from './ReportesParciales/MovementsReport';
// ------------------ Context Consumption --------------------------
import OperatorContext from 'components/contexts/OperatorContext';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  paperRightTop:{
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "50%",
  },
  paperRightBottom: {
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "48.7%",
  }
}));


const ReportesParciales = (props) => {
  const reportesInfoProvided = useContext(OperatorContext);//Context Consumed
  const classes = useStyles();
  // const {backendURL, updateList, username} = props;
  const {updateList} = props;
  // const [backendURL, setBackendURL] = useState('');
  const backendURL=`${process.env.REACT_APP_BACKEND_URL}`
  const [username, setUsername] = useState('');
  console.log(username)

  useEffect(()=>{
    // console.log(reportesInfoProvided);//Context Consumed
    if(reportesInfoProvided.username && reportesInfoProvided.backendURL){
      // setBackendURL(reportesInfoProvided.backendURL);
      setUsername(reportesInfoProvided.username);
    }
  },[reportesInfoProvided])

  return (
    <Grid container spacing={1} style={{height:"100%"}}>
      <Grid item xs={4}>
        <Paper className={classes.paper}>
          <SearchAppBar/>
          <ReceiptsList backendURL={backendURL} updateList={updateList}/>
        </Paper>
      </Grid>
      <Grid item xs={8}>
        <Paper className={classes.paperRightTop}> 
          <CashierReport backendURL={backendURL} updateCashier={updateList}/> 
        </Paper>
        <Paper className={classes.paperRightBottom}>
          <MovementsReport backendURL={backendURL} updateMovements={updateList}/>
        </Paper>
      </Grid>
    </Grid>
  )
};

export default ReportesParciales;