import React, { useCallback, useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TablePagination, TableRow,
  Paper,
} from '@material-ui/core'
import rowsData from './data';
import EnhancedTableHead from './EnhancedTableHead'
import EnhancedTableToolbar from './EnhancedTableToolbar'
import ItemModalWindow from './ModalWindow';
import AddModalWindow from './AddModalWindow';
import {stableSort, getSorting} from 'utils/tableSorting'

export default function CustomersList(props) {
  const {classes} = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [rows, setRowsData] = useState([]);
  const [customersQty, setCustomersQty] = useState(0);
  const [openItemModal, setItemModal] = useState(false)
  const [productId, setProductId] = useState('')
  const [openAddModal, setAddModal] = useState(false)

  //--------------------- Load Rows Data ---------------------
  useEffect(() => {
    const dataFetch = async () => {
      // console.log(await rowsData());
      setRowsData(await rowsData());
    };
    dataFetch()
  }, [])

  useEffect(() => {
    if (rows) {
      setCustomersQty(rows.length)
      // console.log(rows.length);
    }
  }, [rows])

  const handleRequestSort = (event, property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  //----------------- Modal Control ---------------
  const handleCloseModal = useCallback(async () => {
    setRowsData(await rowsData())
    setItemModal(false);
    setAddModal(false);
    // console.log('Cerrando Modal');
  },[])

  const handleClick = (event, id) => {
    setProductId(id)
    setItemModal(true);
    // console.log(id);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSelectedFieldChange = useCallback(async (data) => {
    // setSearchFields(data);
    setRowsData(await rowsData(data))
    // console.log(data);
  }, [])

  const handleOpenAddModal = useCallback(() => {
    setAddModal(true);
  }, [])

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          fieldsChange={handleSelectedFieldChange}
          openAddModal={handleOpenAddModal}
          customers={customersQty}
          classes={props.enhTableToolbarClasses}
        />
        <TableContainer>
          <Table
            className={classes.table}
            size='small'
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {rows.length >= 1 ? (
                stableSort(rows, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onDoubleClick={event => handleClick(event, row.id)}
                        price="checkbox"
                        tabIndex={-1}
                        key={row.name}
                      >
                        <TableCell component="th" id={labelId} scope="row" padding="none">
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.clientStatus}</TableCell>
                        <TableCell align="center">{row.address}</TableCell>
                        <TableCell align="center">{row.whatsapp}</TableCell>
                        <TableCell align="center">{row.creditBalance}</TableCell>
                        <TableCell align="center">{row.creditAmount}</TableCell>
                        <TableCell align="center">{row.lastPurchase}</TableCell>
                        <TableCell align="center">{row.lastPayment}</TableCell>
                        <TableCell align="center">{row.nextPayment}</TableCell>
                      </TableRow>
                    );
                  })) : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <ItemModalWindow open={openItemModal} close={handleCloseModal} id={productId} classes={props.itemModalClasses}/>
      <AddModalWindow open={openAddModal} close={handleCloseModal} classes={props.itemModalClasses}/>
    </div>
  );
}