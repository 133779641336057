import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import { TextField, MenuItem, Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Switch from './SwitchModal'
import departments from 'data/departments'

const depts = departments();

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: "180",
    },
  },
  div: {
    display: "inline-block",
    width: "calc(33.33% - 16px)",
    marginRight: theme.spacing(2)
  },
  divWarning: {
    display: "inline-block",
    width: "calc(33.33% - 16px)",
    marginRight: theme.spacing(2),
    '& .MuiInputBase-input, & .MuiFormLabel-root': {
      color: theme.palette.error.dark
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiOutlinedInput-notchedOutline ': {
      borderWidth: "3px",
      borderColor: theme.palette.error.dark
    },

  },
  fullWidth: {
    display: "inline-block",
    width: "calc(100% - 16px)",
    marginRight: theme.spacing(2)
  },
  fullScreen: {
    width: "30vh",
    height: "30vh"
  },
  noShow: {
    display: "none",
  },
  show: {
    margin: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: 0,
    color: theme.palette.error.dark,
    fontSize: theme.spacing(2),
    textAlign: "center",
    fontStyle: "bold"
  },
  hideBackground: {
    backgroundColor: "transparent !important",
    overflow: "hidden",
    '& .MuiDialog-paperScrollPaper': {
      background: "none"
    },
    '& .MuiPaper-elevation24': {
      boxShadow: "none"
    }
  },
}));

export default function FormPropsTextFields() {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const initialValues = ({
    name: "",
    department: "",
    PLU: "",
    barcode: "",
    price: "",
    cost: "",
    stock: "",
    isWeighted: false
  })
  const [values, setValues] = useState(initialValues)
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [showPriceWarning, setshowPriceWarning] = useState(false);
  const [priceWarningModal, setPriceWarningModal] = useState(false);
  const [productSaved, setProductSaved] = useState(false);
  
  //----------------------- Departmens Load --------------------------
  useEffect(() => {
    const fetchData = async () => {
      setOptions(await depts)
    };
    fetchData();
  }, [])

  //----------------------- Values Refresh --------------------------
  useEffect(() => {
    console.log(values);
    values.price < values.cost ? setshowPriceWarning(true) : setshowPriceWarning(false)
  }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('name'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('department'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('PLU'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('barcode'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('price'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('cost'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('stock'):
        setValues({
          ...values, [name]: parseInt(event.target.value) || ''
        });
        break;
      case ('isWeighted'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
      default:
        break;

    }
  }

  //----------------------- Submit Handler --------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    setHideCircularProgress(false);
    if (values.price < values.cost) {
      setPriceWarningModal(true);
    }else{
      createNewProduct();
    }
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (productSaved) {
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
    setProductSaved(false);
    // console.log(loadModal);
  }, [productSaved])

  //----------------------- Price Warning Modal -----------------------
  const handleClosePriceWarningModal = () => {
    setPriceWarningModal(false);
  }
  //----------------------- Create New Product ------------------------
  const createNewProduct = async () => {
    handleClosePriceWarningModal();
    setLoadModal(true);
    const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/products`, values);
    if(res.data === "Product created."){
      // console.log('Cerrar Loader');
      setProductSaved(true);
      setValues(initialValues)
    }else{
      console.log("Error: ", res.data);
      const timer = setTimeout(() => { handleCloseLoadModal() }, 15000)
      return () => clearTimeout(timer)
    }
  }



  return (
    <form className={classes.root} autoComplete="off" onSubmit={handleSubmit}>
      <div id="newProductName" className={classes.fullWidth}>
        <TextField
          fullWidth
          required
          id="outlined-search1"
          value={values.name}
          onChange={handleChange('name')}
          label="Nombre del Producto"
          type="search"
          variant="outlined"
        />
      </div>
      <div id="newProductDept" className={classes.div}>
        <TextField
          id="standard-select-department"
          fullWidth
          select
          label="Departamento"
          value={values.department}
          onChange={handleChange('department')}
          variant="outlined"
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div id="newProductPLU" className={classes.div}>
        <TextField
          fullWidth
          required
          id="outlined-search2"
          value={values.PLU}
          onChange={handleChange('PLU')}
          label="PLU"
          type="number"
          variant="outlined"
        />
      </div>
      <div id="newProductBcd" className={classes.div}>
        <TextField
          fullWidth
          required
          id="outlined-search3"
          value={values.barcode}
          onChange={handleChange('barcode')}
          label="Código de Barras"
          type="number"
          variant="outlined"
        />
      </div>
      <div id="newProductPrc" className={clsx(classes.div, showPriceWarning && classes.divWarning)}>
        <TextField
          fullWidth
          required
          id="outlined-search4"
          value={values.price}
          onChange={handleChange('price')}
          label="Precio"
          type="number"
          variant="outlined"
        />
      </div>
      <div id="newProductCst" className={classes.div}>
        <TextField
          fullWidth
          required
          id="outlined-search5"
          value={values.cost}
          onChange={handleChange('cost')}
          label="Costo"
          type="number"
          variant="outlined"
        />
      </div>
      <div id="newProductStk" className={classes.div}>
        <TextField
          fullWidth
          required
          id="outlined-search6"
          value={values.stock}
          onChange={handleChange('stock')}
          label="Stock"
          type="number"
          variant="outlined"
          InputProps={{
            inputProps: { min: 0, max: 1000 }
          }}
        />
      </div>
      <div id="priceCostWarning" className={clsx(classes.fullWidth, showPriceWarning && classes.show, !showPriceWarning && classes.noShow)}>
        El precio del producto debe ser mayor que el costo del mismo!
      </div>
      <div id="switchComponent">
        <Switch change={handleChange('isWeighted')} />
      </div>
      <div id='submitButton'>
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          size="large"
        >Crear Producto</Button>
      </div>
      <div id="loadingModal">
        <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
          <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
            <CircularProgress color="primary" size={190} />
          </DialogActions>
        </Dialog>
      </div>
      <div id="priceWarningModalModal">
        <Dialog open={priceWarningModal} onClose={handleClosePriceWarningModal}>
          <DialogTitle>
            Está seguro que desea continuar? El precio de venta debe ser mayor al costo del producto.
          </DialogTitle>
          <DialogActions>
            <Button onClick={createNewProduct}> SI </Button>
            <Button onClick={handleClosePriceWarningModal}> NO </Button>
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}
