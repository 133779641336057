import React from 'react';
import {Button, Dialog, Slide} from '@material-ui/core';
// import Dialog from '@material-ui/core/Dialog';
// import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import PointOfSale from 'components/operator/OperatorNavigation'
import Toolbar from './Toolbar'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(true);

  // useEffect(() => {
  //   var localValue = localStorage.getItem('User Info');
  //   console.log(JSON.parse(localValue));
  //   isLoggedIn('User Info')
  // }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Punto de Venta
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        className="MuiDialog-root-logout"
        disableBackdropClick
        fullScreen
        maxWidth='xl'
        TransitionComponent={Transition}
      >
        <Toolbar title='Punto de Venta' close={handleClose}/>
        <PointOfSale adminView={true} username={props.username}/>
      </Dialog>
      {/* <IsLoggedIn localKey="User Info"/> */}
    </div>
  );
}