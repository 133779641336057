import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import { Grid, TextField, MenuItem, Button, CircularProgress, Dialog, DialogActions } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';
import rolesOptions from 'data/roles';

export default function FormPropsTextFields(props) {
  const {classes} = props;
  const initialValues = ({
    name: "",
    id: "",
    address: "",
    cellphone: "",
    whatsapp: "",
    email: "",
    username: "",
    password: "",
    role: "operator",
    status: true
  })
  const [values, setValues] = useState(initialValues)
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [customerSaved, setCustomerSaved] = useState(false);

  //----------------------- Values Refresh --------------------------
  // useEffect(() => {
  //   console.log(values);
  // }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('name'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('username'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('id'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('address'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('cellphone'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('whatsapp'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('email'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('password'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('role'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('status'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
      default:
        break;

    }
  }

  //----------------------- Submit Handler --------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    setHideCircularProgress(false);
    createNewEmployee();
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (customerSaved) {
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
    setCustomerSaved(false);
    // console.log(loadModal);
  }, [customerSaved])

  //----------------------- Create New Customer ------------------------
  const createNewEmployee = async () => {
    setLoadModal(true);
    const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      name: values.name,
      id: values.id,
      address: values.address,
      cellphone: values.cellphone,
      whatsapp: values.whatsapp,
      email: values.email,
      username: values.username,
      password: values.password,
      role: values.role,
      status: values.status,
      createdBy: JSON.parse(localStorage.getItem('User Info')).username
    });
    if (res.data === "User created") {
      // console.log('Cerrar Loader');
      setCustomerSaved(true);
      setValues(initialValues)
    } else {
      console.log("Error: ", res.data);
      const timer = setTimeout(() => { handleCloseLoadModal() }, 15000)
      return () => clearTimeout(timer)
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search1"
            value={values.name}
            onChange={handleChange('name')}
            label="Nombre del Empleado"
            type="search"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search10"
            value={values.username}
            onChange={handleChange('username')}
            label="Usuario"
            type="search"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            required
            id="standard-select-department"
            fullWidth
            label="Identificación"
            value={values.id}
            onChange={handleChange('id')}
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search2"
            value={values.address}
            onChange={handleChange('address')}
            label="Dirección"
            type="string"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search3"
            value={values.cellphone}
            onChange={handleChange('cellphone')}
            label="Telefono"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search4"
            value={values.whatsapp}
            onChange={handleChange('whatsapp')}
            label="Whatsapp"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search5"
            value={values.email}
            onChange={handleChange('email')}
            label="Email"
            type="email"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            id="outlined-search6"
            value={values.password}
            onChange={handleChange('password')}
            label="Contraseña"
            type="string"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            select
            id="outlined-search7"
            value={values.role}
            onChange={handleChange('role')}
            label="Rol"
            variant="outlined"
            InputProps={{
              inputProps: { min: 0, max: 1000 }
            }}
          >
            {rolesOptions.map(role => (
              <MenuItem key={role.value} value={role.value}>
                {role.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={11}>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            fullWidth
          >Crear Empleado</Button>
        </Grid>
      </Grid>
      <div id="loadingModal">
        <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
          <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
            <CircularProgress color="primary" size={190} />
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}
