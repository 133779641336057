import React, { useState, Fragment } from 'react';
import clsx from 'clsx';
import _ from 'lodash';
import { Card, Box, Avatar, Grid, Typography, Button, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import FindCustomer from './customerInfo/FindCustomer'

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        // height: "100%"
    },
    cardHeaderRoot: {
        display: 'flex',
        padding: '16px',
        alignItems: 'center',
        '& .MuiAvatar-root': {
            marginRight: theme.spacing(2),
            width: '80px',
            height: '80px',
            '& .MuiSvgIcon-root': {
                width: '2em',
                height: '2em',
            },
        },
        '& .MuiAvatar-colorDefault': {
            backgroundColor: theme.palette.primary.light,
        },
        '& .MuiTypography-root': {
            display: "block",
            width: "100%"
        },
        '& > .MuiGrid-container .MuiGrid-item:nth-child(1)': {
            display: 'flex',
        },
    },
    cardHeaderContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    cardContentRoot: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(1),
    },
    bold: {
        fontWeight: 'bold',
        display: 'block',
        // textTransform: 'uppercase',
        fontSize: '1.1rem'
    },
    marginBottom: {
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    iconsContainer: {
        position: 'relative',
        '& .MuiButton-root': {
            minWidth: '0px',
            padding: '5px 10px',
        }
    },
    changeCustomer: {
        position: 'absolute',
        top: theme.spacing(2),
        right: theme.spacing(2),
    },
    more: {
        position: 'absolute',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
}))

const CustomerInfo = (props) => {
    const { customerInfoUpdate } = props;
    const classes = useStyles();
    const [findCustomerDialog, setFindCustomerDialog] = useState(false);
    const [customerInfo, setCustomerInfo] = useState({
        name: 'Cliente Genérico',
        telephone: '0000000000',
        address: 'Cll 27 # 5 - 12',
        email: 'cliente.generico@gmail.com',
        creditAvailable: false,
        creditAmount: 0,
        creditDeadlines: 0,
        creditBalance: 0,
        status: true,
    });
    // OPEN DIALOG
    const openFindCustomerDialog = () => {
        setFindCustomerDialog(true);
    }

    // CLOSE DIALOG
    const closeFindCustomerDialog = () => {
        setFindCustomerDialog(false)
    }

    // TRANSFER CUSTOMER INFO TO PARENT (CHECKOUTWINDOW)
    const handleCustomerUpdate = (data) => {
        setCustomerInfo(data);
        customerInfoUpdate(data);
    }

    return (
        <Card className={classes.root}>
            <Grid container>
                <Grid item xs={11}>
                    <Box className={classes.cardHeaderRoot}>
                        <Grid container>
                            <Grid item xs>
                                <Avatar><PersonIcon /></Avatar>
                                <Box className={classes.cardHeaderContent}>
                                    <Typography variant='h5' component='span' >{_.truncate(customerInfo.name,{'length':20})}</Typography>
                                    <Typography variant='h6' component='span' >{customerInfo.telephone.substring(0, 3) + '-' + customerInfo.telephone.substring(3, 6) + '-' + customerInfo.telephone.substring(6, 10)}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={classes.cardContentRoot}>
                        <Grid container>
                            <Grid item xs={5}>
                                <div>
                                    <span className={classes.bold}>Crédito Disp: </span>
                                    <span> {customerInfo.creditAvailable ? 'Si' : 'No'}</span>
                                </div>
                                <div>
                                    <span className={classes.bold}>Cupo: </span>
                                    <span>$ {customerInfo.creditAmount.toLocaleString('de-DE')}</span>
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <span className={clsx(classes.bold, classes.marginBottom)}>Últimos Pedidos</span>
                                <Button variant='outlined' fullWidth className={clsx(classes.marginBottom)}>
                                    Recibo # 12356
                                </Button>
                                <Button variant='outlined' fullWidth className={clsx(classes.marginBottom)}>
                                    Recibo # 52566
                                </Button>
                                <Button variant='outlined' fullWidth className={clsx(classes.marginBottom)}>
                                    Recibo # 36545
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                {/* ICONS */}
                <Grid item xs={1} className={classes.iconsContainer}>
                    <Button
                        variant="outlined"
                        className={classes.changeCustomer}
                        onClick={openFindCustomerDialog}
                    >
                        <ExitToAppIcon /></Button>
                    <Button variant="outlined" className={classes.more}><MoreHorizIcon /></Button>
                    <FindCustomer
                        findCustomerDialog={findCustomerDialog}
                        closeFindCustomerDialog={closeFindCustomerDialog}
                        setCustomer={handleCustomerUpdate}
                    />
                </Grid>
            </Grid>
        </Card>
    )
}

export default CustomerInfo
