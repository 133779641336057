import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import isLoggedIn from 'components/Login/isLoggedIn';

export default function AlertDialog() {
  const [open, setOpen] = React.useState(true);

  // useEffect(() => {
  //   var localValue = localStorage.getItem('User Info');
  //   console.log(JSON.parse(localValue));
  //   isLoggedIn('User Info')
  // }, [open]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const logoutApp = () => {
    handleClose();
    var userInfo = {};
    userInfo.isAuth = false;

    localStorage.setItem('User Info', JSON.stringify(userInfo));
    // console.log(userInfo);
    isLoggedIn('User Info')
  }

  return (
    <div>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Finalizar Sesión
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="MuiDialog-root-logout"
      >
        <DialogTitle id="alert-dialog-title">{"Desea cerrar la sesión actual?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            NO
          </Button>
          <Button onClick={logoutApp} color="primary" autoFocus>
            SI
          </Button>
        </DialogActions>
      </Dialog>
      {/* <IsLoggedIn localKey="User Info"/> */}
    </div>
  );
}
