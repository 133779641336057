import React from "react";
import {Switch} from "react-router-dom";

import Home from "./components/SignInSide";
import Operator from "./containers/Operator";
import Admin from "./containers/Admin";
import Maintenance from "./containers/Maintenance";

import AppliedRoute from "./AppliedRoute";

export default({childProps}) =>
  <Switch>
    <AppliedRoute exact path="/" component={Home} props={childProps}/>
    <AppliedRoute path="/operator" component={Operator} props={childProps}/>
    <AppliedRoute path='/admin' component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/sales' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/customers' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/reports' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/pos' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/settings' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/admin/logout' exact component={Admin} props={childProps}/>
    <AppliedRoute path='/maintenance' component={Maintenance} props={childProps}/>
  </Switch>

