import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import clsx from 'clsx';
import {
  Grid, TextField, CircularProgress,
  Dialog, DialogActions, InputAdornment, Box, Button
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';


export default function FormPropsTextFields(props) {
  const { classes } = props;
  const initialValues = ({
    clientName: "Nombre del Cliente",
    clientId: "",
    clientAddress: "",
    clientTelephone: "",
    clientWhatsapp: "",
    username: "",
    password: "",
    role: "operator",
    status: true
  })
  const [values, setValues] = useState(initialValues)
  const [loadModal, setLoadModal] = useState(false);
  const [hideCircularProgress, setHideCircularProgress] = useState(false);
  const [customerSaved, setCustomerSaved] = useState(false);

  //----------------------- Values Refresh --------------------------
  useEffect(() => {
    console.log(values);
  }, [values])

  //----------------------- Changes Handler -------------------------- 
  const handleChange = name => (event) => {
    switch (name) {
      case ('clientName'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('clientId'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('clientAddress'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('clientTelephone'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('clientWhatsapp'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('password'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('role'):
        setValues({
          ...values, [name]: event.target.value
        });
        break;
      case ('status'):
        setValues({
          ...values, [name]: !values[name]
        });
        break;
      default:
        break;

    }
  }

  //----------------------- Submit Handler --------------------------
  const handleSubmit = async (event) => {
    event.preventDefault();
    setHideCircularProgress(false);
    createNewEmployee();
  }

  //----------------------- Close Load Modal --------------------------
  const handleCloseLoadModal = () => {
    setHideCircularProgress(true)
    setLoadModal(false)
  }

  //----------------------- Timer to close load modal -----------------
  useEffect(() => {
    if (customerSaved) {
      const timer = setTimeout(() => { handleCloseLoadModal() }, 3000)
      return () => clearTimeout(timer)
    }
    setCustomerSaved(false);
    // console.log(loadModal);
  }, [customerSaved])

  //----------------------- Create New Customer ------------------------
  const createNewEmployee = async () => {
    setLoadModal(true);
    const res = await Axios.post(`${process.env.REACT_APP_BACKEND_URL}/users`, {
      name: values.clientName,
      id: values.clientId,
      address: values.clientAddress,
      telephone: values.clientTelephone,
      whatsapp: values.clientWhatsapp,
      password: values.password,
      role: values.role,
      status: values.status,
      createdBy: JSON.parse(localStorage.getItem('User Info')).username
    });
    if (res.data === "User created") {
      // console.log('Cerrar Loader');
      setCustomerSaved(true);
      setValues(initialValues)
    } else {
      console.log("Error: ", res.data);
      const timer = setTimeout(() => { handleCloseLoadModal() }, 15000)
      return () => clearTimeout(timer)
    }
  }

  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={11} sm={12}>
          <TextField
            fullWidth
            required
            id="outlined-search10"
            value={values.clientId}
            onChange={handleChange('clientId')}
            label="Usuario"
            type="search"
            variant="outlined"
            InputProps={{
              endAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            id="outlined-search1"
            value={values.clientName}
            onChange={handleChange('clientName')}
            label="Nombre del Cliente"
            type="search"
            variant="outlined"
            InputProps={{
              readOnly: true,
            }}
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search2"
            value={values.clientAddress}
            onChange={handleChange('address')}
            label="Dirección"
            type="string"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search3"
            value={values.clientTelephone}
            onChange={handleChange('cellphone')}
            label="Telefono"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={6}>
          <TextField
            fullWidth
            required
            id="outlined-search4"
            value={values.clientWhatsapp}
            onChange={handleChange('whatsapp')}
            label="Whatsapp"
            type="number"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={11} sm={12}>
          <Box component="div" m={1}>
            <Button variant='contained' color='primary'> + </Button> 
          </Box>
        </Grid>
        <Grid item xs={11}>
          <Button
            variant="contained"
            type="submit"
            color="secondary"
            size="large"
            fullWidth
          >Crear Pedido</Button>
        </Grid>
      </Grid>
      <div id="loadingModal">
        <Dialog open={loadModal} onClose={handleCloseLoadModal} className={clsx(classes.hideBackground, hideCircularProgress && classes.noShow)}>
          <DialogActions className={clsx(classes.hideBackground, classes.fullScreen)}>
            <CircularProgress color="primary" size={190} />
          </DialogActions>
        </Dialog>
      </div>
    </form>
  );
}
