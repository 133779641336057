import Axios from 'axios';
function createData(_id, id, name, orderStatus, address, whatsapp, paymentMethod, total, delivery) {
  return { _id, id, name, orderStatus, address, whatsapp, paymentMethod, total, delivery };
}

const data = async (searchParams) => {
  let rows = [];
  let receiptsArr = [];
  var params = searchParams || { inputField: "", selectField: "name" }

  if (params.selectField === "name" && params.inputField.length >= 2) {
    // console.log(params)
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/closedReceipts/regex?input=${params.inputField}&option=${params.selectField}`)
    // console.log(res.data);
    receiptsArr = res.data;
  } else if ((params.selectField === "username" || params.selectField === "id" || params.selectField === "cellphone" 
    || params.selectField === "whatsapp" || params.selectField === "role") && params.inputField.length >= 1) {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/closedReceipts/regex?input=${params.inputField}&option=${params.selectField}`)
    receiptsArr = res.data;
  } else {
    const res = await Axios.get(`${process.env.REACT_APP_BACKEND_URL}/closedReceipts`);
    receiptsArr = res.data;
    // console.log(receiptsArr[0]);
  }

  receiptsArr.map(receipt => {
    return rows.push(
      createData(receipt._id, receipt.receipt, receipt.clientName, receipt.orderStatus || 'no info', receipt.address || 'Client address', receipt.whatsapp || 'Client Whatsapp', receipt.paymentMethod,
        receipt.total, receipt.delivery || 'true')
    );

  })
  // console.log(rows[0])

  // const rows2 = [
  //   createData('1','id','Cliente1', 'Facturado', 'Calle 45 # 27 - 68', '301-601-2062', "454.000","Cash", "true"),
  //   createData('1','id','Cliente2', 'En Camino', "Calle 45 # 27 - 68", '301-601-2062', "150.000","Credit", "false"),
  //   createData('1','id','Cliente3', 'En Preparación', 'Calle 45 # 27 - 68', '301-601-2062', "220.000","Credit Cards", "true"),
  //   createData('1','id','Cliente4', 'Pendiente', 'Calle 45 # 27 - 68', '301-601-2062', "80.000","Debit Cards", "false"),
  //   createData('1','id','Cliente5', 'Facturado', 'Calle 45 # 27 - 68', '301-601-2062', "454.000","Cash", "true"),
  //   createData('1','id','Cliente6', 'En Camino', 'Calle 45 # 27 - 68', '301-601-2062', "150.000","Credit", "false"),
  //   createData('1','id','Cliente7', 'En Preparación', 'Calle 45 # 27 - 68', '301-601-2062', "220.000","Credit Cards", "true"),
  //   createData('1','id','Cliente8', 'Pendiente', 'Calle 45 # 27 - 68', '301-601-2062', "80.000","Debit Cards", "false"),
  //   createData('1','id','Cliente9', 'Facturado', 'Calle 45 # 27 - 68', '301-601-2062', "454.000","Cash", "true"),
  //   createData('1','id','Cliente10', 'En Camino', 'Calle 45 # 27 - 68', '301-601-2062',"150.000", "Credit", "false"),
  //   createData('1','id','Cliente11', 'En Preparación', 'Calle 45 # 27 - 68', '301-601-2062',"220.000", "Credit", "true"),
  //   createData('1','id','Cliente12', 'Pendiente', 'Calle 45 # 27 - 68', '301-601-2062', "454.000","Cash", "false"),
  //   createData('1','id','Cliente13', 'Facturado', 'Calle 45 # 27 - 68', '301-601-2062', "150.000","Debit Cards", "true")
  // ];
  // console.log(rows2[0])
  // console.log(searchParams)
  return rows;
}

export default data;