const getSubtotal = (products) => {
  var count = 0
  if (products.length > 0) {
    products.map(item => (
      count = count + (item.qty * item.price)
    ))
  }
  return count;
}

export default getSubtotal;