import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ProgressBar from './ProgressBar'
import Title from '../../Title';
import moment from 'moment';
import 'moment/locale/es';

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles(theme => ({
  root: {
   
  },
  depositContext: {
    // flex: 1,
    marginBottom: theme.spacing(3),
  },
  changeGoal: {
    width:"100%",
    position: "absolute",
    left: 0,
    bottom: theme.spacing(2),
  }
}));

export default function Deposits() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Title>Meta Diaria</Title>
      <Typography component="p" variant="h4">
        $10.500.000
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        para {moment().format('L')}
      </Typography>
      <div>
        <ProgressBar goal={10500000}/>
      </div>
      <div className={classes.changeGoal}>
        <Link color="primary" href="#" onClick={preventDefault}>
          Cambiar meta
        </Link>
      </div>
    </div>
  );
}
