import React from 'react'
import { SvgIcon } from '@material-ui/core'
export default function DiscountIcon(props) {
  return (
    <SvgIcon {...props}>
      <svg height="24px" viewBox="-16 0 384 384.056" width="24px" xmlns="http://www.w3.org/2000/svg"><path d="m154.507812 375.6875c6.160157 5.585938 13.863282 8.367188 21.558594 8.367188 7.664063 0 15.304688-2.757813 21.359375-8.253907l144.105469-128.121093c9.855469-8.96875 13.128906-22.792969 8.320312-35.214844-4.816406-12.433594-16.527343-20.464844-29.855468-20.464844h-23.96875v-160c0-17.648438-14.351563-32-32-32h-176c-17.648438 0-32 14.351562-32 32v160h-23.96875c-13.328125 0-25.039063 8.03125-29.847656 20.464844-4.808594 12.421875-1.535157 26.246094 8.457031 35.335937zm-98.480468-151.6875c17.648437 0 32-14.351562 32-32v-160h176v160c0 17.648438 14.351562 32 32 32l24.105468-.121094-144.234374 128-143.839844-127.878906zm0 0"/><path d="m192.027344 288v-10.359375c23.105468-6.90625 40-28.328125 40-53.640625 0-30.878906-25.128906-56-56-56-13.230469 0-24-10.769531-24-24s10.769531-24 24-24c13.230468 0 24 10.769531 24 24 0 8.832031 7.167968 16 16 16 8.832031 0 16-7.167969 16-16 0-25.320312-16.894532-46.734375-40-53.640625v-10.359375c0-8.832031-7.167969-16-16-16-8.832032 0-16 7.167969-16 16v10.359375c-23.097656 6.90625-40 28.328125-40 53.640625 0 30.878906 25.128906 56 56 56 13.230468 0 24 10.769531 24 24s-10.769532 24-24 24c-13.230469 0-24-10.769531-24-24 0-8.832031-7.167969-16-16-16-8.832032 0-16 7.167969-16 16 0 25.320312 16.902344 46.734375 40 53.640625v10.359375c0 8.832031 7.167968 16 16 16 8.832031 0 16-7.167969 16-16zm0 0"/></svg>
      {/* <svg style="width:24px;height:24px" viewBox="0 0 24 24"> */}
      {/* <path /*fill="currentColor"*d="M18.65,2.85L19.26,6.71L22.77,8.5L21,12L22.78,15.5L19.24,17.29L18.63,21.15L14.74,20.54L11.97,23.3L9.19,20.5L5.33,21.14L4.71,17.25L1.22,15.47L3,11.97L1.23,8.5L4.74,6.69L5.35,2.86L9.22,3.5L12,0.69L14.77,3.46L18.65,2.85M9.5,7A1.5,1.5 0 0,0 8,8.5A1.5,1.5 0 0,0 9.5,10A1.5,1.5 0 0,0 11,8.5A1.5,1.5 0 0,0 9.5,7M14.5,14A1.5,1.5 0 0,0 13,15.5A1.5,1.5 0 0,0 14.5,17A1.5,1.5 0 0,0 16,15.5A1.5,1.5 0 0,0 14.5,14M8.41,17L17,8.41L15.59,7L7,15.59L8.41,17Z" /> */}
    </SvgIcon>
  )
}