import Axios from 'axios';

const fetchProductsInChosenReceipt = async (backendURL, receiptNumber) => {
  var productsArray = [];
  const res = await Axios.get(`${backendURL}/operations?receipt=${receiptNumber}`);
  res.data.map(item => {
    return productsArray.push(item);
  })
  return productsArray;
}

export default fetchProductsInChosenReceipt;